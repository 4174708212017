import React, { useState, useEffect, useCallback } from "react";
import {
  Menu,
  Dropdown,
  Button,
  Table,
  Tag,
  Tabs,
  Space,
  Radio,
  DatePicker,
  Checkbox,
  Select,
  Modal,
  Typography,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import moment from "moment";
import { useSelector, useDispatch } from "../../utilities/hooks";
import accessIcon from "../../assets/images/noAccess.svg";
import {
  fetchTransactionsAction,
  fetchStatementAction,
  deleteStatementAction,
} from "../../services/actions";
import Loader from "../../components/loaders/loading";
import {
  formatDate,
  formatBalance,
  formatBalanceCrypto,
} from "../../utilities/helperFunctions";
import gears from "../../assets/images/filter-gears.svg";
import { useHistory } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import CryptoTransactions from "./cryptoTable";
import CardTransactions from "./cardTable";
import _ from "lodash";
import { ReactComponent as DebitIcon } from "../../assets/images/debit-icon.svg";
import { ReactComponent as CreditIcon } from "../../assets/images/credit-icon.svg";
import { ReactComponent as ExchangeIcon } from "../../assets/images/exchange-improve.svg";
//import { AuthError } from "../../components/Errors/AuthError";

const { Paragraph } = Typography;

const { TabPane } = Tabs;

export default function TransactionsContainer() {
  const { show, boot } = useIntercom();
  const history = useHistory();
  const { transactions, loading } = useSelector(
    (state) => state.transactionsReducer
  );
  // console.log(transactions, "tsts");
  const { statement, loading: loadingStatement } = useSelector(
    (state) => state.statementReducer
  );
  const { wallets } = useSelector((state) => state.walletsReducer);
  const { user } = useSelector((state) => state.profileReducer);

  boot({
    name: user?.account?.name,
    email: user?.account?.email,
    user_id: user?.account?.id,
    isB2B: "B2B",
  });

  const dispatch = useDispatch();
  const [isLoading, setLoadingState] = useState(false);
  const [isDownloadVisible, setIsDownloadVisible] = useState(false);
  const [dateValue, setDateValue] = useState("thirtydays");
  const [dateValueFilter, setDateValueFilter] = useState("thirtydays");
  const [fromDateValueMoment, setFromDateValueMoment] = useState(
    moment().subtract(30, "days")
  );
  const [toDateValueMoment, setToDateValueMoment] = useState(moment());
  const [fromDateValueMomentFilter, setFromDateValueMomentFilter] = useState(
    moment().subtract(30, "days")
  );
  const [toDateValueMomentFilter, setToDateValueMomentFilter] = useState(
    moment()
  );

  const [isFilledFilter, setIsFilledFilter] = useState(false);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [isLoadingStateFilter, setIsLoadingStateFilter] = useState(false);
  const [wallet, setWallet] = useState("");
  const [transactionType, setTransactionType] = useState([]);
  const [transactionStatus, setTransactionStatus] = useState([]);
  const [downloadButton, setDownloadButton] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasKyc, setHasKyc] = useState(true);

  const enabledWallets = _.remove(
    _.concat([], wallets),
    ({ enabled }) => enabled
  );
  const isActive = enabledWallets?.some((item) => item?.isCrypto === true);

  const walletCopy = JSON.parse(JSON.stringify(wallets));

  const checkForCrypto = (value) => {
    if (value === null || value === undefined) {
      return { isCrypto: false };
    }
    return walletCopy.find((wallet) => wallet.currency === value);
  };

  const transactionMessages = {
    cardFunding: "Deposited from USD",
    fundCard: "Deposited from USD",
    createCard: "Deposited from USD",
    cardCreation: "Card creation",
    DeletedCard: "Card deletion",
    cardWithdraw: "Withdrawal to USD",
    withdrawCard: "Withdrawal to USD",
    CardSubscription: "Card subscription",
    CardFXCharge: "Card FX charge",
    AutoConversion: "USD negative balance auto conversion from base wallet",
  };

  useEffect(() => {
    if (user.account && user.account.isVerified === false) {
      setHasKyc(false);
    } else {
      // dispatch(fetchWalletsAction());
      dispatch(fetchTransactionsAction({}));
      setHasKyc(true);
    }
  }, [user, dispatch]);

  function onChangeFilter(value) {
    setWallet(value);
  }

  function onChangeFilterType(value) {
    setTransactionType(value);
  }

  function handleDatePickerChange(date) {
    setFromDateValueMoment(date);
  }

  function handleDatePickerChangeTo(date) {
    setToDateValueMoment(date);
  }

  const isDisabled =
    !fromDateValueMoment || !toDateValueMoment || loadingStatement;

  useEffect(() => {
    if (
      fromDateValueMomentFilter ||
      fromDateValueMomentFilter ||
      transactionType ||
      transactionStatus ||
      !!wallet
    ) {
      setIsFilledFilter(true);
    } else {
      setIsFilledFilter(false);
    }
    setIsLoadingStateFilter(loading);
  }, [
    wallet,
    fromDateValueMomentFilter,
    transactionStatus,
    transactionType,
    toDateValueMomentFilter,
    loading,
  ]);

  const toggleDownloadVisible = useCallback(() => {
    setIsDownloadVisible(!isDownloadVisible);
  }, [isDownloadVisible]);

  useEffect(() => {
    setLoadingState(loading);
  }, [loading]);

  useEffect(() => {
    if (statement) {
      setDownloadButton(statement);
      setIsModalVisible(true);
      setIsDownloadVisible(false);
    }
  }, [statement]);

  const transactionColumns = [
    {
      title: "Date time",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 220,
      render: (createdAt) => {
        return formatDate(createdAt);
      },
    },
    {
      title: "Transaction ID",
      dataIndex: "transactionId",
      key: "transactionId",
      fixed: "left",
      render: (transactionId) => {
        return (
          <Paragraph
            copyable={{
              text: transactionId,
            }}
          >
            {transactionId.substring(0, 9)}...
          </Paragraph>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => {
        let IconComponent;

        switch (type) {
          case "exchange":
            IconComponent = ExchangeIcon;
            break;

          case "payout":
            IconComponent = DebitIcon;
            break;

          case "collection":
            IconComponent = CreditIcon;
            break;

          case "cardFunding":
            IconComponent = CreditIcon;
            break;

          case "fundCard":
            IconComponent = CreditIcon;
            break;

          case "DeletedCard":
            IconComponent = DebitIcon;
            break;

          case "createCard":
            IconComponent = DebitIcon;
            break;

          case "cardWithdraw":
            IconComponent = DebitIcon;
            break;

          case "withdrawCard":
            IconComponent = DebitIcon;
            break;

          case "CardSubscription":
            IconComponent = DebitIcon;
            break;

          case "cardCreation":
            IconComponent = DebitIcon;
            break;

          case "CardFXCharge":
            IconComponent = DebitIcon;
            break;

          case "AutoConversion":
            IconComponent = DebitIcon;
            break;

          default:
            IconComponent = CreditIcon;
        }

        return (
          <div className="d-flex align-items-center">
            <IconComponent className="mr-2" />

            <span className="capitalize-text">
              {transactionMessages[type] || type}
            </span>
          </div>
        );
      },
    },
    {
      title: "Balance Before",
      dataIndex: "balanceBefore",
      key: "balanceBefore",
      render(balanceBefore, row) {
        return `${row?.currency} ${
          checkForCrypto(row?.currency).isCrypto
            ? formatBalanceCrypto(balanceBefore)
            : formatBalance(balanceBefore)
        }`;
      },
    },
    {
      title: "Sent amount",
      dataIndex: "amount",
      key: "amount",
      render(amount, row) {
        return `${row?.currency} ${formatBalance(amount)}`;
      },
    },
    {
      title: "Dest-amount",
      dataIndex: "destinationAmount",
      key: "destinationAmount",
      render(destinationAmount, row) {
        return `${
          checkForCrypto(row?.destinationCurrency).isCrypto
            ? row?.destinationCurrency +
              " " +
              formatBalanceCrypto(destinationAmount)
            : row?.destinationCurrency
            ? row?.destinationCurrency + " " + formatBalance(destinationAmount)
            : formatBalance(destinationAmount)
        }`;
      },
    },
    {
      title: "Fees",
      dataIndex: "meta",
      key: "meta",
      render(meta, row) {
        return `${row?.currency} ${formatBalance(row?.fees)}`;
      },
    },
    {
      title: "Balance After",
      dataIndex: "balanceAfter",
      key: "balanceAfter",
      render(balanceAfter, row) {
        return `${row?.currency} ${formatBalance(balanceAfter)}`;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      fixed: "right",
      render: (status) => {
        const styles = {
          successful: {
            color: "#264E04",
          },
          failed: {
            color: "#A30010",
          },
          pending: {
            color: "#3127F1",
          },
        };

        const statusStyles = {
          successful: { color: "#E2FCCC", style: styles.successful },
          failed: { color: "#FCCCD6", style: styles.failed },
          pending: { color: "#CECDFD", style: styles.pending },
        };

        return (
          <Tag
            color={statusStyles[status].color}
            style={statusStyles[status].style}
          >
            <span className="capitalize-text">{status}</span>
          </Tag>
        );
      },
    },
  ];

  const plainOptions = [
    {
      label: "Success",
      value: "successful",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Failed",
      value: "failed",
    },
  ];
  const { Option } = Select;

  const onChangeStatus = (checkedValues) => {
    setTransactionStatus(checkedValues);
  };

  const changeDateValue = (e) => {
    setDateValue(e.target.value);
    if (e.target.value === "thirtydays") {
      setFromDateValueMoment(moment().subtract(30, "days"));
      setToDateValueMoment(moment());
    } else if (e.target.value === "today") {
      setFromDateValueMoment(moment().startOf("day"));
      setToDateValueMoment(moment().endOf("day"));
    } else if (e.target.value === "sevendays") {
      setFromDateValueMoment(moment().subtract(7, "days"));
      setToDateValueMoment(moment());
    } else {
      setFromDateValueMoment(moment());
      setToDateValueMoment(moment());
    }
  };

  const changeDateValueFilter = (e) => {
    setDateValueFilter(e.target.value);
    if (e.target.value === "thirtydays") {
      setFromDateValueMomentFilter(moment().subtract(30, "days"));
      setToDateValueMomentFilter(moment());
    } else if (e.target.value === "today") {
      setFromDateValueMomentFilter(moment().startOf("day"));
      setToDateValueMomentFilter(moment().endOf("day"));
    } else if (e.target.value === "sevendays") {
      setFromDateValueMomentFilter(moment().subtract(7, "days"));
      setToDateValueMomentFilter(moment());
    } else {
      setFromDateValueMomentFilter(moment());
      setToDateValueMomentFilter(moment());
    }
  };

  const filterTransactions = () => {
    dispatch(
      fetchTransactionsAction({
        params: {
          from: fromDateValueMomentFilter.format("YYYY-MM-DD"),
          to: toDateValueMomentFilter.format("YYYY-MM-DD"),
          status: transactionStatus,
          type: transactionType,
        },
      })
    );
  };

  const downloadStatement = async (e) => {
    e.preventDefault();
    setIsLoadingState(true);

    try {
      await dispatch(
        fetchStatementAction({
          params: {
            from: fromDateValueMoment.format("YYYY-MM-DD"),
            to: toDateValueMoment.format("YYYY-MM-DD"),
            currencies: [],
          },
        })
      );
    } finally {
      setIsLoadingState(false);
    }
  };

  const getRow = (item) => {
    history.push({
      pathname: `/transaction/details/${item.transactionId}`,
      // state: `${JSON.stringify({ ...item })}`,
    });
  };

  const menu = (
    <Menu>
      <div className="playground-filter">
        <div className="pt-3 pb-1 px-3">
          <h1>Date</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Radio.Group value={dateValueFilter} onChange={changeDateValueFilter}>
            <Radio.Button value="today">Today</Radio.Button>
            <Radio.Button value="sevendays">Last 7 Days</Radio.Button>
            <Radio.Button value="thirtydays">Last 30 Days</Radio.Button>
            <Radio.Button value="custom">Custom</Radio.Button>
          </Radio.Group>
        </div>
        <div className="pt-3 pb-1 px-3">
          <h1>Range</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Space size={12}>
            <DatePicker
              style={{ width: "180px" }}
              onChange={() => {}}
              value={fromDateValueMomentFilter}
            />
            <DatePicker
              style={{ width: "180px" }}
              onChange={() => {}}
              value={toDateValueMomentFilter}
            />
          </Space>
        </div>
        <div className="pt-3 pb-1 px-3">
          <h1>Status</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Checkbox.Group
            options={plainOptions}
            value={transactionStatus}
            onChange={onChangeStatus}
          />
        </div>
        <div className="pt-3 pb-1 px-3">
          <h1>Transaction type</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Select
            // showSearch
            style={{ width: "100%" }}
            placeholder="All Transactions"
            onChange={onChangeFilterType}
          >
            <Option value="exchange">Exchange</Option>
            <Option value="payout">Payout</Option>
            <Option value="collection">Collections</Option>
          </Select>
        </div>
        <div className="pt-3 pb-1 px-3">
          <h1>Currency</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Select
            // showSearch
            style={{ width: "100%" }}
            placeholder="All Currency"
            onChange={onChangeFilter}
          >
            {wallets
              .sort((a, b) => a.position - b.position)
              .map((option, index) =>
                option.enabled === true ? (
                  <Option value={option.currency} key={index}>
                    {option.currency}
                  </Option>
                ) : null
              )}
          </Select>
        </div>
        <div className="pt-3 pb-2 px-3 text-right">
          <Button
            type="primary btn-primary"
            size="large"
            onClick={filterTransactions}
            disabled={isFilledFilter === false ? true : false}
            loading={isLoadingStateFilter}
          >
            Filter
          </Button>
        </div>
      </div>
    </Menu>
  );

  const menuDownload = (
    <Menu>
      <div className="playground-filter">
        <div className="pt-3 px-3">
          <h1>Filter your download</h1>
        </div>
        <hr />
        <div className="pt-1 pb-1 px-3">
          <h1>Date</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Radio.Group value={dateValue} onChange={changeDateValue}>
            <Radio.Button value="today">Today</Radio.Button>
            <Radio.Button value="sevendays">Last 7 Days</Radio.Button>
            <Radio.Button value="thirtydays">Last 30 Days</Radio.Button>
            <Radio.Button value="custom">Custom</Radio.Button>
          </Radio.Group>
        </div>
        <div className="pt-3 pb-1 px-3">
          <h1>Range</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Space size={12}>
            <DatePicker
              style={{ width: "180px" }}
              onChange={handleDatePickerChange}
              value={fromDateValueMoment}
            />
            <DatePicker
              style={{ width: "180px" }}
              onChange={handleDatePickerChangeTo}
              value={toDateValueMoment}
            />
          </Space>
        </div>

        <div className="pt-3 pb-1 px-3">
          <h1>Currency</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Select
            // showSearch
            style={{ width: "100%" }}
            placeholder="All Currency"
            onChange={onChangeFilter}
            allowClear
          >
            {wallets
              .sort((a, b) => a.position - b.position)
              .map((option, index) =>
                option.enabled === true ? (
                  <Option value={option.currency} key={index}>
                    {option.currency}
                  </Option>
                ) : null
              )}
          </Select>
        </div>
        <div className="pt-3 pb-1 px-3">
          <h1>Type</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Select
            style={{ width: "100%" }}
            placeholder="Type"
            onChange={onChangeFilterType}
          >
            <Option value="account">Account</Option>

            <Option value="crypto">Crypto</Option>
          </Select>
        </div>
        <div className="pt-3 pb-2 px-3 text-right">
          <Button
            type="primary btn-primary"
            size="large"
            onClick={downloadStatement}
            disabled={isDisabled}
            loading={isLoadingState}
          >
            {isLoadingState ? "" : "Download Statement"}
          </Button>
        </div>
      </div>
    </Menu>
  );

  function getData(item) {
    dispatch(
      fetchTransactionsAction({
        params: { limit: item.pageSize, page: item.current },
      })
    );
  }

  const handleCancel = () => {
    dispatch(deleteStatementAction());
    setIsModalVisible(false);
  };

  return (
    <div>
      {/* {errorMessage ? <AuthError text={errorMessage} /> : null} */}
      <div>
        <h1 className="text-md color-bold text-bold mt-2">Transactions</h1>
      </div>
      {hasKyc ? (
        <div>
          {isLoading ? (
            <Loader />
          ) : (
            <Tabs defaultActiveKey="1" onChange={() => {}}>
              <TabPane tab="Fiat" key="1">
                <div>
                  {!transactions || transactions.transactions?.length === 0 ? (
                    <div className="completed-kyc-state">
                      <div className="row">
                        <div className="col-md-12 text-center">
                          <img src={accessIcon} alt="" />
                          <h1>No transactions</h1>
                          <p>You have not perfomed any transactions yet</p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="bg-white p-2 this-table"
                      style={{ position: "relative" }}
                    >
                      <div className="transaction-filters">
                        <Space wrap>
                          <Dropdown
                            overlay={menu}
                            placement="bottomRight"
                            trigger="click"
                          >
                            <Button>
                              <span>
                                <img src={gears} alt="" />
                              </span>
                              &nbsp; Filtered by 30 days <DownOutlined />
                            </Button>
                          </Dropdown>
                          <Dropdown
                            overlay={menuDownload}
                            placement="bottomRight"
                            trigger="click"
                            visible={isDownloadVisible}
                            onVisibleChange={toggleDownloadVisible}
                          >
                            <Button
                              style={{
                                backgroundColor: "#5a50fe",
                                color: "white",
                              }}
                            >
                              Download
                              <DownOutlined />
                            </Button>
                          </Dropdown>
                        </Space>
                      </div>
                      <Table
                        columns={transactionColumns}
                        dataSource={transactions.transactions}
                        pagination={{
                          pageSize: transactions.limit,
                          pageSizeOptions: [10, 20, 30],
                          showSizeChanger: true,
                          total: transactions.total,
                          position: ["topLeft"],
                          current: transactions.page,
                          showTitle: true,
                          showTotal: (total) => `Total result: ${total} `,
                        }}
                        onRow={(record) => {
                          return {
                            onClick: () => {
                              getRow(record);
                            }, // click row
                          };
                        }}
                        rowKey="transactionId"
                        scroll={{ x: "max-content" }}
                        onChange={(pagination) => {
                          getData(pagination);
                        }}
                      />
                    </div>
                  )}
                </div>
              </TabPane>
              <TabPane tab="Card" key="2">
                <CardTransactions show={show} />
              </TabPane>
              {isActive ? (
                <TabPane tab="Crypto" key="3">
                  <CryptoTransactions show={show} />
                </TabPane>
              ) : null}
            </Tabs>
          )}
        </div>
      ) : (
        <div className="completed-kyc-state">
          <div className="row">
            <div className="col-md-12 text-center">
              <img src={accessIcon} alt="" />
              <h1>You do not have access</h1>
              <p>
                Please complete your KYB verification, to be able to view
                contents of this page, click on the “complete KYB” button
              </p>
              <button onClick={show} style={{ cursor: "pointer" }}>
                Complete KYB
              </button>
            </div>
          </div>
        </div>
      )}

      <Modal
        centered
        footer={null}
        visible={isModalVisible}
        onCancel={handleCancel}
      >
        <div>
          <h1 className="text-md color-bold mt-2 text-center">
            Your statement is ready. Click the button below to open it.
          </h1>
          <br />
          <div className="text-center dt-btn">
            <a
              href={downloadButton}
              download="MyExampleDoc"
              target="_blank"
              rel="noreferrer"
            >
              <Button className={{ pointerEvents: "none" }}>
                View Statement
              </Button>
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
}
