import React, { useState, useEffect } from "react";
import { Button, Input, Form, Row, Col, message } from "antd";
import "react-phone-input-2/lib/style.css";
import StyledModal from "../styled-modal";
import makeAPICall from "../../../utilities/apiUtils";
import CountryPhoneInput from "../../CountryPhoneInput";
import countries from "../../../utilities/countries.json";
import { useSelector } from "../../../utilities/hooks";
import ReactFlagsSelect from "react-flags-select";
import { State } from "country-state-city";

export default function CreateCardUserModal({ show, onClose }) {
  const { user } = useSelector((state) => state.profileReducer);
  const { sourceCountries } = useSelector((state) => state.CountryReducer);
  const allowedCountriesArray = sourceCountries.map((item) => item.code);
  const [selected, setSelected] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [stateList, setStateList] = useState([]);

  const defaultCountry = countries.find(
    (country) => country.code === selected || country.code === user.country
  );
  const selectedCountries = countries?.filter((country) =>
    allowedCountriesArray?.includes(country.code)
  );

  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phonenumber: "",
    localPhone: "",
    dialCode: "",
    country: "",
    city: "",
    zip: "",
    state: "",
    idType: "",
    idNumber: "",
    address: "",
  });

  const [incomplete, setIncomplete] = useState(true);

  const allDetails = (details) => {
    // Remove the leading zero if present
    const adjustedValue = details?.phoneNumber.startsWith("0")
      ? details?.phoneNumber.slice(1)
      : details?.phoneNumber;
    setProfile({
      ...profile,
      phonenumber: `${details?.selectedCountry?.dial_code}${adjustedValue}`,
      localPhone: adjustedValue,
      dialCode: details?.selectedCountry?.dial_code,
      country: details?.selectedCountry?.code,
    });
  };

  useEffect(() => {
    setIncomplete(false);

    if (!profile.firstName || profile.firstName === "") setIncomplete("true");
    if (!profile.lastName || profile.lastName === "") setIncomplete("true");
    if (!profile.phonenumber || profile.phonenumber === "")
      setIncomplete("true");
    if (!profile.email || profile.email === "") setIncomplete("true");
    if (!profile.country || profile.country === "") setIncomplete("true");
    if (!profile.city || profile.city === "") setIncomplete("true");
    if (!profile.zip || profile.zip === "") setIncomplete("true");
    if (!profile.state || profile.state === "") setIncomplete("true");
    if (!profile.idType || profile.idType === "") setIncomplete("true");
    if (!profile.idNumber || profile.idNumber === "") setIncomplete("true");
    if (!profile.address || profile.address === "") setIncomplete("true");
  }, [profile]);

  useEffect(() => {
    if (selected) {
      const countryState = State.getStatesOfCountry(selected);
      setStateList(countryState);
    }
  }, [selected]);

  const onSubmitForm = () => {
    const data = {
      ...(profile.firstName && { firstName: profile.firstName }),
      ...(profile.lastName && { lastName: profile.lastName }),
      ...(profile.email && { email: profile.email }),
      ...(profile.phonenumber && { phone: profile.phonenumber }),
      ...(profile.country && { country: profile.country }),
      ...(profile.city && { city: profile.city }),
      ...(profile.zip && { zipCode: profile.zip }),
      ...(profile.state && { state: profile.state }),
      ...(profile.idType && { idType: profile.idType }),
      ...(profile.idNumber && { idNumber: profile.idNumber }),
      ...(profile.address && { address: profile.address }),
    };

    setIsLoading(true);
    return makeAPICall({
      path: `cards/user`,
      payload: data,
      method: "POST",
    })
      .then((res) => {
        message.success({
          content: "User created successfully",
          duration: 4,
        });
        setIsLoading(false);
        setProfile({
          firstName: "",
          lastName: "",
          email: "",
          phonenumber: "",
          localPhone: "",
          dialCode: "",
          country: "",
          city: "",
          zip: "",
          state: "",
          idType: "",
          idNumber: "",
          address: "",
        });
        onClose();
      })
      .catch((err) => {
        setIsLoading(false);
        message.error({ content: err.message, duration: 4 });
      });
  };

  function closeModal() {
    setProfile({
      firstName: "",
      lastName: "",
      email: "",
      phonenumber: "",
      localPhone: "",
      dialCode: "",
      country: "",
      city: "",
      zip: "",
      state: "",
      idType: "",
      idNumber: "",
      address: "",
    });
    onClose();
  }

  const onChangeInputValue = (event) => {
    setProfile({
      ...profile,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <StyledModal centered show={show} footer={null} onClose={closeModal}>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 offset-3 mt-5">
            <div>
              <div className="mt-4">
                <p className="text-bold color-bold text-md mb-1">
                  Create card user
                </p>
              </div>
              <Form layout="vertical">
                <Input.Group size="large">
                  <Row>
                    <Col span={11}>
                      <Form.Item label="First Name">
                        <Input
                          name="firstName"
                          value={profile.firstName}
                          onChange={onChangeInputValue}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11} push={2}>
                      <Form.Item label="Last Name">
                        <Input
                          name="lastName"
                          value={profile.lastName}
                          onChange={onChangeInputValue}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>
                <Input.Group size="large">
                  <Row>
                    <Col span={11}>
                      <Form.Item label="Email">
                        <Input
                          name="email"
                          value={profile.email}
                          onChange={onChangeInputValue}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11} push={2}>
                      <Form.Item label="Country">
                        <ReactFlagsSelect
                          className="form__select_flag"
                          placeholder="Select user country"
                          selected={selected}
                          onSelect={(code) => setSelected(code)}
                          searchable="true"
                          countries={allowedCountriesArray}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>
                <Input.Group size="large">
                  <Row>
                    <Col span={11}>
                      <Form.Item label="State">
                        <select
                          className="reg-select"
                          onChange={(e) => {
                            setProfile({
                              ...profile,
                              state: e.target.value,
                            });
                          }}
                        >
                          {stateList.map((item, index) => (
                            <option key={index} value={item.name}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </Form.Item>
                    </Col>
                    <Col span={11} push={2}>
                      <Form.Item label="City">
                        <Input
                          name="city"
                          value={profile.city}
                          onChange={onChangeInputValue}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>

                <Input.Group size="large">
                  <Row>
                    <Col span={11}>
                      <Form.Item label="Phone">
                        <CountryPhoneInput
                          countries={selectedCountries}
                          defaultCountry={defaultCountry}
                          allDetails={allDetails}
                          phoneNumber={profile.localPhone}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11} push={2}>
                      <Form.Item label="Address">
                        <Input
                          name="address"
                          value={profile.address}
                          onChange={onChangeInputValue}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>

                <Input.Group size="large">
                  <Row>
                    <Col span={11}>
                      <Form.Item label="Zip code">
                        <Input
                          name="zip"
                          value={profile.zip}
                          onChange={onChangeInputValue}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11} push={2}>
                      <Form.Item label="Identification document type">
                        <select
                          className="reg-select"
                          onChange={(e) => {
                            setProfile({
                              ...profile,
                              idType: e.target.value,
                            });
                          }}
                        >
                          <>
                            <option value="National_ID">
                              National ID card
                            </option>
                            <option value="Passport">
                              International passport
                            </option>
                            <option value="Driving_License">
                              Driver's license
                            </option>
                          </>
                        </select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>

                <Input.Group size="large">
                  <Row>
                    <Col span={11}>
                      <Form.Item label="Identification number">
                        <Input
                          name="idNumber"
                          value={profile.idNumber}
                          onChange={onChangeInputValue}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Input.Group>

                <Button
                  type="primary"
                  className="simple-block-btn text-bold"
                  size="large"
                  block
                  loading={isLoading}
                  disabled={incomplete}
                  onClick={onSubmitForm}
                >
                  Create card user
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </StyledModal>
  );
}
