import React, { useState } from "react";
import { Input, message, Avatar } from "antd";
import { ReactComponent as SearchIcon } from "../../../../assets/images/search-close.svg";
import noRequest from "../../../../assets/images/no-request.png";
import makeAPICall from "../../../../utilities/apiUtils";
import Loader from "../../../loaders/loading";

export default function First({ next, userDetailsTwo }) {
  const [searchValue, setSearchValue] = useState("");
  const [userDetails, setUserDetails] = useState(userDetailsTwo);
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setSearchValue(e.target.value);
  };

  const handleClear = () => {
    setSearchValue("");
  };

  // Helper function to check if the input is a valid email
  const isValidEmail = (input) => {
    // Basic email validation, you can customize this according to your requirements
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(input);
  };

  function fetchUser() {
    if (isValidEmail(searchValue)) {
      setLoading(true);
      const payload = {
        email: searchValue,
      };
      return makeAPICall({
        path: "settings/internal/accounts",
        payload,
        method: "POST",
      })
        .then((res) => {
          const { data } = res;
          if (data.merchantExists) {
            setLoading(false);
            setSearchValue("");
            setUserDetails(data);
          } else {
            setLoading(false);
            setUserDetails(null);
            message.error(
              `No Eversend account with the email ${searchValue} exists`,
              5
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.message, 5);
        });
    } else {
      setLoading(true);
      const payload = {
        tag: searchValue,
      };
      return makeAPICall({
        path: "settings/internal/accounts",
        payload,
        method: "POST",
      })
        .then((res) => {
          const { data } = res;
          if (data.merchantExists) {
            setLoading(false);
            setSearchValue("");
            setUserDetails(data);
          } else {
            setLoading(false);
            setUserDetails(null);
            message.error(
              `No Eversend account with the tag ${searchValue} exists`,
              5
            );
          }
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.message, 5);
        });
    }
  }
  return (
    <div>
      <div className="banktransfer-others">
        <div className="d-flex align-items-center">
          <div className="input-card">
            <Input
              placeholder="Enter Eversend user tag or email"
              suffix={
                <SearchIcon
                  onClick={handleClear}
                  style={{ cursor: "pointer" }}
                />
              }
              value={searchValue}
              onChange={handleChange}
              // onPressEnter={fetchUser}
            />
          </div>
          {searchValue ? (
            <button
              className="request-search-btn ml-2"
              style={{ cursor: "pointer" }}
              disabled={!searchValue}
              onClick={fetchUser}
            >
              Search
            </button>
          ) : null}
        </div>

        {loading ? (
          <Loader />
        ) : (
          <div>
            {userDetails ? (
              <div className="d-flex mt-4 align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  {userDetails?.avatar ? (
                    <Avatar src={userDetails?.avatar} />
                  ) : (
                    <Avatar
                      style={{
                        backgroundColor: "#F4F5F6",
                        border: "1px solid #CBCAE5",
                        color: "#7F56D9",
                      }}
                    >
                      {userDetails?.firstName?.charAt(0)}&nbsp;
                      {userDetails?.lastName?.charAt(0)}
                    </Avatar>
                  )}

                  <h4 className="ml-2">
                    {userDetails?.firstName} {userDetails?.lastName}
                  </h4>
                </div>
                <h3 onClick={() => next(userDetails)}>Request</h3>
              </div>
            ) : (
              <div className="text-center mt-5">
                <img src={noRequest} alt="no request" />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
