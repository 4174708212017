import React, { useState, useEffect } from "react";
import {
  Menu,
  Dropdown,
  Button,
  Table,
  Tag,
  Space,
  Radio,
  DatePicker,
  Checkbox,
  Select,
  Modal,
  Typography,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import moment from "moment";
import { useSelector, useDispatch } from "../../utilities/hooks";
import accessIcon from "../../assets/images/noAccess.svg";
import {
  fetchCryptoTransactionsAction,
  fetchStatementAction,
  deleteStatementAction,
} from "../../services/actions";
import Loader from "../../components/loaders/loading";
import {
  formatDate,
  formatBalanceCrypto,
} from "../../utilities/helperFunctions";
import gears from "../../assets/images/filter-gears.svg";
//import { AuthError } from "../../components/Errors/AuthError";

const { Paragraph } = Typography;

export default function CryptoTransactions({ show }) {
  const { cryptoTransactions, loadingCrypto } = useSelector(
    (state) => state.transactionsReducer
  );
  // console.log(transactions, "tsts");
  const { statement, loading: loadingStatement } = useSelector(
    (state) => state.statementReducer
  );
  const { wallets } = useSelector((state) => state.walletsReducer);
  const { user } = useSelector((state) => state.profileReducer);

  const dispatch = useDispatch();
  const [isLoading, setLoadingState] = useState(false);
  const [dateValue, setDateValue] = useState("thirtydays");
  const [dateValueFilter, setDateValueFilter] = useState("thirtydays");
  const [fromDateValueMoment, setFromDateValueMoment] = useState(
    moment().subtract(30, "days")
  );
  const [toDateValueMoment, setToDateValueMoment] = useState(moment());
  const [fromDateValueMomentFilter, setFromDateValueMomentFilter] = useState(
    moment().subtract(30, "days")
  );
  const [toDateValueMomentFilter, setToDateValueMomentFilter] = useState(
    moment()
  );

  const [isFilled, setIsFilled] = useState(false);
  const [isFilledFilter, setIsFilledFilter] = useState(false);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [isLoadingStateFilter, setIsLoadingStateFilter] = useState(false);
  const [currencies, setCurriencies] = useState([]);
  const [transactionType, setTransactionType] = useState([]);
  const [transactionStatus, setTransactionStatus] = useState([]);
  const [currenciesOne, setCurrienciesOne] = useState([]);
  const [downloadButton, setDownloadButton] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasKyc, setHasKyc] = useState(true);

  // useEffect(() => {
  //   dispatch(fetchProfileAction());
  // }, [dispatch]);

  useEffect(() => {
    if (user.account && user.account.isVerified === false) {
      setHasKyc(false);
    } else {
      setHasKyc(true);
      // dispatch(fetchWalletsAction());
      dispatch(fetchCryptoTransactionsAction({}));
    }
  }, [user, dispatch]);

  function onChange(value) {
    setCurrienciesOne(value);
  }

  function onChangeFilter(value) {
    setCurriencies(value);
  }

  function onChangeFilterType(value) {
    setTransactionType(value);
  }

  function handleDatePickerChange(date) {
    setFromDateValueMoment(date);
  }

  function handleDatePickerChangeTo(date) {
    setToDateValueMoment(date);
  }

  useEffect(() => {
    if (
      fromDateValueMoment === "" ||
      fromDateValueMoment === "" ||
      currenciesOne.length === 0
    ) {
      setIsFilled(false);
    } else {
      setIsFilled(true);
    }
    setIsLoadingState(loadingStatement);
  }, [currenciesOne, fromDateValueMoment, toDateValueMoment, loadingStatement]);

  useEffect(() => {
    if (
      fromDateValueMomentFilter === "" ||
      fromDateValueMomentFilter === "" ||
      transactionType === "" ||
      transactionStatus === "" ||
      currencies.length === 0
    ) {
      setIsFilledFilter(false);
    } else {
      setIsFilledFilter(true);
    }
    setIsLoadingStateFilter(loadingCrypto);
  }, [
    currencies,
    fromDateValueMomentFilter,
    transactionStatus,
    transactionType,
    toDateValueMomentFilter,
    loadingCrypto,
  ]);

  // useEffect(() => {
  //   dispatch(fetchWalletsAction());
  // }, [dispatch]);

  useEffect(() => {
    setLoadingState(loadingCrypto);
  }, [loadingCrypto]);

  // useEffect(() => {
  //   dispatch(fetchCryptoTransactionsAction({}));
  // }, [dispatch]);

  const transactionColumns = [
    {
      title: "Transaction Hash",
      dataIndex: "meta",
      key: "meta",
      fixed: "left",
      render: (meta) => {
        return (
          <p
            style={{ cursor: "pointer", textDecoration: "underline" }}
            onClick={() => handleCheckInfo(meta?.hash, meta?.asset)}
          >
            <Paragraph
              copyable={{
                text: meta?.hash,
              }}
            >
              {meta?.hash?.substring(0, 9) ||
                meta?.blockchainHash?.substring(0, 9) ||
                "N/A"}
              ...
            </Paragraph>
            {/* <span>
              <Paragraph
                copyable={{
                  text: meta?.blockchainHash,
                }}
              >
                {meta?.blockchainHash.substring(0, 9)}...
              </Paragraph>
            </span> */}
          </p>
        );
      },
    },
    {
      title: "Asset Type",
      dataIndex: "address",
      key: "address",
      render: (address) => {
        return address?.coin;
      },
    },
    {
      title: "Balance Before",
      dataIndex: "address",
      key: "address",
      width: 200,
      render(address) {
        return `${address?.coin} ${formatBalanceCrypto(address?.amount || 0)}`;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: 200,
      render(amount, row) {
        return `${row?.address?.coin} ${formatBalanceCrypto(amount || 0)}`;
      },
    },
    {
      title: "Date time",
      dataIndex: "createdAt",
      key: "createdAt",
      width: 200,
      render: (createdAt) => {
        return formatDate(createdAt);
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      fixed: "right",
      // defaultSortOrder: "descend",
      // sorter: (a, b) => a.status - b.status,
      render: (status) => {
        if (status === "COMPLETED") {
          return (
            <Tag
              color="#E2FCCC"
              style={{ color: "#264E04", border: "1px solid #264E04" }}
            >
              {status}
            </Tag>
          );
        } else if (
          status === "FAILED" ||
          "REJECTED" ||
          "BLOCKED" ||
          "CANCELLED"
        ) {
          return (
            <Tag
              color="#FCCCD6"
              style={{ color: "#A30010", border: "1px solid #A30010" }}
            >
              {status}
            </Tag>
          );
        } else {
          return (
            <Tag
              color="#CECDFD"
              style={{ color: "#3127F1", border: "1px solid #3127F1" }}
            >
              {status}
            </Tag>
          );
        }
      },
    },
  ];

  const handleCheckInfo = (address, assets) => {
    const url = checkSite(assets);
    window.open(url + address, "_blank");
  };

  const checkSite = (asset) => {
    if (asset === "BTC") {
      return "https://www.blockchain.com/btc/address/";
    } else if (asset === "ETH") {
      return "https://etherscan.io/address/";
    } else if (asset === "XDC" || asset === "XDT") {
      return "https://tronscan.org/#/transaction/";
    } else {
      return "https://etherscan.io/address/";
    }
  };

  const plainOptions = [
    {
      label: "Success",
      value: "successful",
    },
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "Failed",
      value: "failed",
    },
  ];
  const { Option } = Select;

  const onChangeStatus = (checkedValues) => {
    setTransactionStatus(checkedValues);
  };

  const changeDateValue = (e) => {
    setDateValue(e.target.value);
    if (e.target.value === "thirtydays") {
      setFromDateValueMoment(moment().subtract(30, "days"));
      setToDateValueMoment(moment());
    } else if (e.target.value === "today") {
      setFromDateValueMoment(moment().startOf("day"));
      setToDateValueMoment(moment().endOf("day"));
    } else if (e.target.value === "sevendays") {
      setFromDateValueMoment(moment().subtract(7, "days"));
      setToDateValueMoment(moment());
    } else {
      setFromDateValueMoment(moment());
      setToDateValueMoment(moment());
    }
  };

  const changeDateValueFilter = (e) => {
    setDateValueFilter(e.target.value);
    if (e.target.value === "thirtydays") {
      setFromDateValueMomentFilter(moment().subtract(30, "days"));
      setToDateValueMomentFilter(moment());
    } else if (e.target.value === "today") {
      setFromDateValueMomentFilter(moment().startOf("day"));
      setToDateValueMomentFilter(moment().endOf("day"));
    } else if (e.target.value === "sevendays") {
      setFromDateValueMomentFilter(moment().subtract(7, "days"));
      setToDateValueMomentFilter(moment());
    } else {
      setFromDateValueMomentFilter(moment());
      setToDateValueMomentFilter(moment());
    }
  };

  const filterTransactions = () => {
    dispatch(
      fetchCryptoTransactionsAction({
        params: {
          from: fromDateValueMomentFilter.format("YYYY-MM-DD"),
          to: toDateValueMomentFilter.format("YYYY-MM-DD"),
          currencies: currencies,
          status: transactionStatus,
          type: transactionType,
        },
      })
    );
  };

  useEffect(() => {
    if (statement) {
      setDownloadButton(statement);
      showModal();
    }
  }, [statement]);

  const downloadStatement = (e) => {
    e.preventDefault();

    dispatch(
      fetchStatementAction({
        params: {
          from: fromDateValueMoment.format("YYYY-MM-DD"),
          to: toDateValueMoment.format("YYYY-MM-DD"),
          currencies: currenciesOne,
        },
      })
    );
  };

  const menu = (
    <Menu>
      <div className="playground-filter">
        <div className="pt-3 pb-1 px-3">
          <h1>Date</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Radio.Group value={dateValueFilter} onChange={changeDateValueFilter}>
            <Radio.Button value="today">Today</Radio.Button>
            <Radio.Button value="sevendays">Last 7 Days</Radio.Button>
            <Radio.Button value="thirtydays">Last 30 Days</Radio.Button>
            <Radio.Button value="custom">Custom</Radio.Button>
          </Radio.Group>
        </div>
        <div className="pt-3 pb-1 px-3">
          <h1>Range</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Space size={12}>
            <DatePicker
              style={{ width: "180px" }}
              onChange={() => {}}
              value={fromDateValueMomentFilter}
            />
            <DatePicker
              style={{ width: "180px" }}
              onChange={() => {}}
              value={toDateValueMomentFilter}
            />
          </Space>
        </div>
        <div className="pt-3 pb-1 px-3">
          <h1>Status</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Checkbox.Group
            options={plainOptions}
            value={transactionStatus}
            onChange={onChangeStatus}
          />
        </div>
        <div className="pt-3 pb-1 px-3">
          <h1>Transaction type</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Select
            // showSearch
            style={{ width: "100%" }}
            placeholder="All Transactions"
            onChange={onChangeFilterType}
          >
            <Option value="exchange">Exchange</Option>
            <Option value="payout">Payout</Option>
            <Option value="collection">Collections</Option>
          </Select>
        </div>
        <div className="pt-3 pb-1 px-3">
          <h1>Currency</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Select
            // showSearch
            style={{ width: "100%" }}
            placeholder="All Currency"
            onChange={onChangeFilter}
          >
            {wallets
              .sort((a, b) => a.position - b.position)
              .map((option, index) =>
                option.enabled === true ? (
                  <Option value={option.currency} key={index}>
                    {option.currency}
                  </Option>
                ) : null
              )}
          </Select>
        </div>
        <div className="pt-3 pb-2 px-3 text-right">
          <Button
            type="primary btn-primary"
            size="large"
            onClick={filterTransactions}
            disabled={isFilledFilter === false ? true : false}
            loading={isLoadingStateFilter}
          >
            Filter
          </Button>
        </div>
      </div>
    </Menu>
  );

  const menuDownload = (
    <Menu>
      <div className="playground-filter">
        <div className="pt-3 px-3">
          <h1>Filter your download</h1>
        </div>
        <hr />
        <div className="pt-1 pb-1 px-3">
          <h1>Date</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Radio.Group value={dateValue} onChange={changeDateValue}>
            <Radio.Button value="today">Today</Radio.Button>
            <Radio.Button value="sevendays">Last 7 Days</Radio.Button>
            <Radio.Button value="thirtydays">Last 30 Days</Radio.Button>
            <Radio.Button value="custom">Custom</Radio.Button>
          </Radio.Group>
        </div>
        <div className="pt-3 pb-1 px-3">
          <h1>Range</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Space size={12}>
            <DatePicker
              style={{ width: "180px" }}
              onChange={handleDatePickerChange}
              value={fromDateValueMoment}
            />
            <DatePicker
              style={{ width: "180px" }}
              onChange={handleDatePickerChangeTo}
              value={toDateValueMoment}
            />
          </Space>
        </div>

        <div className="pt-3 pb-1 px-3">
          <h1>Currency</h1>
        </div>
        <div className="dateHandle py-3 px-3">
          <Select
            // showSearch
            style={{ width: "100%" }}
            placeholder="All Currency"
            onChange={onChange}
            mode="multiple"
            allowClear
          >
            {wallets
              .sort((a, b) => a.position - b.position)
              .map((option, index) =>
                option.enabled === true ? (
                  <Option value={option.currency} key={index}>
                    {option.currency}
                  </Option>
                ) : null
              )}
          </Select>
        </div>
        <div className="pt-3 pb-2 px-3 text-right">
          <Button
            type="primary btn-primary"
            size="large"
            onClick={downloadStatement}
            disabled={isFilled === false ? true : false}
            loading={isLoadingState}
          >
            {isLoadingState ? "" : "Download Statement"}
          </Button>
        </div>
      </div>
    </Menu>
  );

  function getData(item) {
    dispatch(
      fetchCryptoTransactionsAction({
        params: { limit: item.pageSize, page: item.current },
      })
    );
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    dispatch(deleteStatementAction());
    setIsModalVisible(false);
  };

  return (
    <div>
      {hasKyc ? (
        <div>
          {isLoading ? (
            <Loader />
          ) : (
            <div>
              {!cryptoTransactions ||
              cryptoTransactions.transactions?.length === 0 ? (
                <div className="completed-kyc-state">
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <img src={accessIcon} alt="" />
                      <h1>No transactions</h1>
                      <p>You have not perfomed any transactions yet</p>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="bg-white p-2 this-table"
                  style={{ position: "relative" }}
                >
                  <div className="transaction-filters">
                    <Space wrap>
                      <Dropdown overlay={menu} placement="bottomRight">
                        <Button>
                          <span>
                            <img src={gears} alt="" />
                          </span>
                          &nbsp; Filtered by 30 days <DownOutlined />
                        </Button>
                      </Dropdown>
                      <Dropdown.Button
                        overlay={menuDownload}
                        icon={<DownOutlined />}
                        placement="bottomRight"
                      >
                        Download
                      </Dropdown.Button>
                    </Space>
                  </div>
                  <Table
                    columns={transactionColumns}
                    dataSource={cryptoTransactions.transactions}
                    pagination={{
                      pageSize: cryptoTransactions.limit,
                      pageSizeOptions: [10, 20, 30],
                      showSizeChanger: true,
                      total: cryptoTransactions.total,
                      position: ["topLeft"],
                      current: cryptoTransactions.page,
                      showTitle: true,
                      showTotal: (total) => `Total result: ${total} `,
                    }}
                    rowKey="transactionId"
                    scroll={{ x: "max-content" }}
                    onChange={(pagination) => {
                      getData(pagination);
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="completed-kyc-state">
          <div className="row">
            <div className="col-md-12 text-center">
              <img src={accessIcon} alt="" />
              <h1>You do not have access</h1>
              <p>
                Please complete your KYB verification, to be able to view
                contents of this page, click on the “complete KYB” button
              </p>
              <button onClick={show} style={{ cursor: "pointer" }}>
                Complete KYB
              </button>
            </div>
          </div>
        </div>
      )}
      <Modal
        centered
        footer={null}
        visible={isModalVisible}
        onCancel={handleCancel}
      >
        <div>
          <h1 className="text-md color-bold mt-2 text-center">
            Click the button below to download statement
          </h1>
          <br />
          <div className="text-center dt-btn">
            <a
              href={downloadButton}
              download="MyExampleDoc"
              target="_blank"
              rel="noreferrer"
            >
              <Button>Download Statement</Button>
            </a>
          </div>
        </div>
      </Modal>
    </div>
  );
}
