import React, { useState } from "react";
import back from "../../../../assets/images/back-button.svg";
import check from "../../../../assets/images/success-check-mark.svg";
import { Avatar, message } from "antd";
import { formatMoney } from "../../../../utilities/helperFunctions";
import makeAPICall from "../../../../utilities/apiUtils";
import Loader from "../../../Loader";

export default function Third({ prev, allData, close, newTrans }) {
  const [makeRequest, setMakeRequest] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //   console.log(allData, "test");

  function sendRequest() {
    setIsLoading(true);
    const payload = {
      currency: allData.currencyDetails.currency,
      amount: allData.currencyDetails.amount,
      userEmail: allData.userDetails.email,
      reason: allData.currencyDetails.reason || "request money",
    };
    return makeAPICall({
      path: "transactions/collections/eversend",
      payload,
      method: "POST",
    })
      .then((res) => {
        // console.log(res);
        setIsLoading(false);
        setMakeRequest(true);
      })
      .catch((err) => {
        setIsLoading(false);
        message.error(err.message, 5);
      });
  }
  return (
    <div>
      {makeRequest ? (
        <div className="banktransfer-others">
          <div className="text-center">
            <img src={check} alt="success" />
            <br />
            <h1 className="wallet-request-title-bold mt-3 mb-3">
              Funding request sent
            </h1>
            <div className="wallet-request-title mb-3">
              You have requested {allData?.currencyDetails?.currency}&nbsp;
              {formatMoney(allData?.currencyDetails?.amount)} from &nbsp;
              {allData?.userDetails?.firstName} {allData?.userDetails?.lastName}
            </div>
            <div className="remaining-time">
              <div className="d-flex align-items-center">
                <div className="request-expire">Request would expire in </div>
                <div className="request-expire-time ml-2">2minutes</div>
              </div>
            </div>
            <br />
            <br />
            <button
              style={{ cursor: "pointer" }}
              className="grey-btn-btn mb-4"
              onClick={newTrans}
            >
              Make another transaction
            </button>
            <button
              className="btn-btn-btn"
              style={{ cursor: "pointer" }}
              onClick={close}
            >
              Go to home
            </button>
          </div>
        </div>
      ) : (
        <div>
          <div
            className="d-flex align-items-center"
            onClick={prev}
            style={{ cursor: "pointer", marginTop: "-80px" }}
          >
            <img src={back} alt="back arrow" />
            <p className="ml-2 money-request-p">Back</p>
          </div>
          <div
            className="banktransfer-others"
            style={{
              margin: "0 auto 0 auto",
              width: "90%",
              marginTop: "-20px",
            }}
          >
            <h1 className="wallet-request-title-bold mb-1 text-center">
              Confirm Transaction
            </h1>

            <br />
            <div className="text-center">
              {allData?.userDetails?.avatar ? (
                <Avatar src={allData?.userDetails?.avatar} size={50} />
              ) : (
                <Avatar
                  style={{
                    backgroundColor: "#F4F5F6",
                    border: "1px solid #CBCAE5",

                    color: "#7F56D9",
                  }}
                >
                  {allData?.userDetails?.firstName.charAt(0)}
                  {allData?.userDetails?.lastName.charAt(0)}
                </Avatar>
              )}
            </div>
            <h5 className="wallet-request-title-new text-center mt-2 mb-1">
              {allData?.userDetails?.firstName} {allData?.userDetails?.lastName}
            </h5>
            <h6 className="text-center small-request-money mb-4">
              Request money
            </h6>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h6 className="text-center small-request-money">
                Request amount
              </h6>
              <h5 className="wallet-request-title-new">
                {allData?.currencyDetails?.currency}
                {formatMoney(allData?.currencyDetails?.amount)}
              </h5>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h6 className="text-center small-request-money">
                Eversend user phone number
              </h6>
              <h5 className="wallet-request-title-new">
                {allData?.userDetails?.phoneNumber.prefix}
                {allData?.userDetails?.phoneNumber.number}
              </h5>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <h6 className="text-center small-request-money">Reason</h6>
              <h5 className="wallet-request-title-new">
                {allData?.currencyDetails?.reason}
              </h5>
            </div>
            <div className="d-flex align-items-center justify-content-between mb-5">
              <h6 className="text-center small-request-money">
                Eversend user email
              </h6>
              <h5 className="wallet-request-title-new">
                {allData?.userDetails?.email}
              </h5>
            </div>
            <div className="text-center">
              <button
                className="btn-btn-btn"
                style={{ cursor: "pointer" }}
                onClick={sendRequest}
                // onClick={() => next({ currency: currency, amount: amount })}
              >
                {isLoading ? (
                  <span className="d-flex align-items-center justify-between">
                    <Loader dark={false} />
                  </span>
                ) : (
                  "Continue"
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
