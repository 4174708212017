import React, { useState, useEffect } from "react";
import { useSelector } from "../../../utilities/hooks";
import { Tooltip, Input, Radio, Space } from "antd";
import CustomSelect from "../../../components/CustomSelect";
import notifyWhite from "../../../assets/images/notify-white.svg";
import countries from "../../../utilities/countries.json";
import { formatMoney } from "../../../utilities/helperFunctions";
import back from "../../../assets/images/back-button.svg";
import ReactFlagsSelect from "react-flags-select";
import CountryPhoneInput from "../../../components/CountryPhoneInput";
import makeAPICall from "../../../utilities/apiUtils";
import { message } from "antd";
import Loader from "../../../components/Loader";
import { State } from "country-state-city";

export default function First({ next, card, cardDetails }) {
  const { user } = useSelector((state) => state.profileReducer);
  const { sourceCountries } = useSelector((state) => state.CountryReducer);
  const allowedCountriesArray = sourceCountries.map((item) => item.code);
  const [showAdd, setShowAdd] = useState(false);
  const { wallets } = useSelector((state) => state.walletsReducer);
  const [selectedUser, setSelectedUser] = useState(null);
  const [cardBrand, setCardBrand] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isNonSubscription, setIsNonSubscription] = useState(true);
  const [inputValues, setInputValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    localPhone: "",
    dialCode: "",
    country: "",
    city: "",
    zip: "",
    state: "",
    idType: "",
    idNumber: "",
    address: "",
  });
  const [defaultUser, setDefaultUser] = useState("Select a user");
  const [selected, setSelected] = useState("");
  const [allUsers, setAllUsers] = useState();
  const [stateList, setStateList] = useState([]);
  const defaultCountry = countries.find(
    (country) => country.code === selected || country.code === user.country
  );
  const selectedCountries = countries?.filter((country) =>
    allowedCountriesArray?.includes(country.code)
  );

  const [title, setTitle] = useState(cardDetails.title);
  let USDWallet = wallets.find((wallet) => wallet.currency === "USD");

  const handleCardTitleChange = (event) => {
    setTitle(event.target.value);
    cardDetails({ title: event.target.value });
  };

  const toolTipCurrency = (
    <div className="d-flex align-items-start">
      <img src={notifyWhite} alt="notify" />
      <h1 className="tooltip-h1 ml-2">
        This is the wallet you fund your card from
      </h1>
    </div>
  );

  function handleChangeInput(event) {
    setInputValues({
      ...inputValues,
      [event.target.name]: event.target.value,
    });
  }

  const handleSelect = (value) => {
    if (value === "add") {
      setShowAdd(true);
    } else {
      card.userId = value.id;
      setSelectedUser(value.id);
      cardDetails({ userId: value.id });
    }
  };

  const allDetails = (details) => {
    // Remove the leading zero if present
    const adjustedValue = details?.phoneNumber.startsWith("0")
      ? details?.phoneNumber.slice(1)
      : details?.phoneNumber;
    setInputValues({
      ...inputValues,
      phone: `${details?.selectedCountry?.dial_code}${adjustedValue}`,
      localPhone: adjustedValue,
      dialCode: details?.selectedCountry?.dial_code,
      country: details?.selectedCountry?.code,
    });
  };

  const prev = () => {
    setShowAdd(false);
  };

  const handleSubmitApprove = () => {
    const data = {
      ...(inputValues.firstName && { firstName: inputValues.firstName }),
      ...(inputValues.lastName && { lastName: inputValues.lastName }),
      ...(inputValues.email && { email: inputValues.email }),
      ...(inputValues.phone && { phone: inputValues.phone }),
      ...(inputValues.country && { country: inputValues.country }),
      ...(inputValues.city && { city: inputValues.city }),
      ...(inputValues.zip && { zipCode: inputValues.zip }),
      ...(inputValues.state && { state: inputValues.state }),
      ...(inputValues.idType && { idType: inputValues.idType }),
      ...(inputValues.idNumber && { idNumber: inputValues.idNumber }),
      ...(inputValues.address && { address: inputValues.address }),
    };
    setLoading(true);
    return makeAPICall({
      path: "cards/user",
      method: "post",
      payload: data,
    })
      .then((res) => {
        setLoading(false);
        // getCards()
        const { userId } = res.data.data;
        setDefaultUser(`${inputValues.firstName} ${inputValues.lastName}`);
        card.userId = userId;
        setSelectedUser(userId);
        cardDetails({ userId: userId });
        setShowAdd(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message, 5);
      });
  };

  function getCards() {
    return makeAPICall({
      path: "cards/getUsers",
      method: "GET",
    })
      .then((res) => {
        setAllUsers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getCards();
    cardDetails({ isNonSubscription: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selected) {
      const countryState = State.getStatesOfCountry(selected);
      setStateList(countryState);
    }
  }, [selected]);

  const handleOptionSelect = (value) => {
    card.brand = value;
    cardDetails({ brand: value });
    setCardBrand(value);
  };

  const handleChecked = (e) => {
    setIsNonSubscription(e.target.value);
    cardDetails({ isNonSubscription: e.target.value });
  };

  return (
    <div>
      {showAdd ? (
        <div>
          <div
            className="d-flex align-items-center"
            onClick={prev}
            style={{ cursor: "pointer", marginTop: "-40px" }}
          >
            <img src={back} alt="back arrow" />
            <p className="ml-2 money-request-p">Back</p>
          </div>
          <br />
          <div className="banktransfer-others">
            <div className="form-group">
              <label className="label-form">New beneficiary name</label>
              <div className="input-row">
                <input
                  type="text"
                  name="firstName"
                  value={inputValues.firstName}
                  onChange={handleChangeInput}
                  placeholder="First"
                />
                <input
                  type="text"
                  name="lastName"
                  value={inputValues.lastName}
                  onChange={handleChangeInput}
                  placeholder="Last"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="label-form">Country</label>
              <ReactFlagsSelect
                className="form__select_flag"
                placeholder="Select country"
                selected={selected}
                onSelect={(code) => setSelected(code)}
                searchable="true"
                countries={allowedCountriesArray}
              />
            </div>
            <div className="form-group">
              <label className="label-form">State</label>
              <select
                className="reg-select"
                onChange={(e) => {
                  setInputValues({
                    ...inputValues,
                    state: e.target.value,
                  });
                }}
              >
                {stateList.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="form-group">
              <label className="label-form">City</label>
              <input
                type="text"
                name="city"
                value={inputValues.city}
                onChange={handleChangeInput}
                placeholder="eg. Lagos"
                className="reg-input"
              />
            </div>
            <div className="form-group">
              <label className="label-form">Address</label>
              <textarea
                name="address"
                value={inputValues.address}
                onChange={handleChangeInput}
                placeholder="eg. Kyandondo road 4"
                className="reg-input"
              />
            </div>
            <div className="form-group">
              <label className="label-form">Email</label>
              <input
                type="email"
                name="email"
                value={inputValues.email}
                onChange={handleChangeInput}
                placeholder="example@mail.co "
                className="reg-input"
              />
            </div>
            <div className="form-group">
              <label className="label-form">User phone number</label>
              <CountryPhoneInput
                countries={selectedCountries}
                defaultCountry={defaultCountry}
                allDetails={allDetails}
                phoneNumber={inputValues.localPhone}
              />
            </div>
            <div className="form-group">
              <label className="label-form">Zip code</label>
              <input
                type="number"
                name="zip"
                value={inputValues.zip}
                onChange={handleChangeInput}
                placeholder="eg. 100001"
                className="reg-input"
              />
            </div>
            <div>
              <label className="label-form">Identification document type</label>
              <select
                onChange={(e) => {
                  setInputValues({
                    ...inputValues,
                    idType: e.target.value,
                  });
                }}
                className="reg-select"
              >
                <>
                  <option value="National_ID">National ID card</option>
                  <option value="Passport">International passport</option>
                  <option value="Driving_License">Driver's license</option>
                </>
              </select>
            </div>
            <br />
            <div className="form-group">
              <label className="label-form">Identification number</label>
              <input
                type="text"
                name="idNumber"
                value={inputValues.idNumber}
                onChange={handleChangeInput}
                placeholder="eg. B1198876"
                className="reg-input"
              />
            </div>
            <br />
            <button
              type="submit"
              disabled={
                !(
                  inputValues.firstName.trim() &&
                  inputValues.lastName.trim() &&
                  inputValues.email.trim() &&
                  inputValues.city.trim() &&
                  inputValues.zip.trim() &&
                  inputValues.idNumber.trim() &&
                  inputValues.address
                )
              }
              // disabled={true}
              onClick={handleSubmitApprove}
              className="submit-reg"
            >
              {loading ? (
                <span className="d-flex align-items-center justify-between">
                  <Loader dark={false} />
                </span>
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </div>
      ) : (
        <div className="banktransfer-others">
          <p className="wallet-addmoney-title mb-2">
            Who will be using this card?
          </p>
          {allUsers && (
            <CustomSelect
              options={allUsers}
              defaultValue={defaultUser}
              handleSelect={handleSelect}
            />
          )}

          <br />
          <p className="wallet-addmoney-title mb-2">
            Enter Card Name or Purpose
          </p>
          <Input
            placeholder="Eg. Company expense card"
            className="reg-select"
            value={title}
            name="card_title"
            onChange={handleCardTitleChange}
            size="large"
          />
          <br />
          <br />
          <p className="wallet-addmoney-title mb-2">Enter card type</p>
          <select
            onChange={(e) => handleOptionSelect(e.target.value)}
            className="reg-select"
            // style={{ height: "40px" }}
          >
            <>
              <option value="visa">Select card</option>
              <option value="visa">Visa card</option>
              <option value="mastercard">Master card</option>
            </>
          </select>
          <br />
          <br />

          <p className="wallet-addmoney-title mb-2">Subscription Type</p>
          <div className="d-flex align-items-center">
            <Radio.Group onChange={handleChecked} value={isNonSubscription}>
              <Space direction="vertical">
                <Radio value={true}>
                  <h4> $3 One-time Payment</h4>
                  <p>
                    Pay a one-time fee for unlimited card use with no monthly
                    charges
                  </p>
                </Radio>
                <Radio value={false}>
                  <h4> $1 Monthly Fee</h4>
                  <p>
                    Pay a small 1 USD fee each month for ongoing card
                    management.
                  </p>
                </Radio>
              </Space>
            </Radio.Group>
          </div>
          <br />
          <p className="wallet-addmoney-title mb-2">Source wallet</p>
          <Tooltip placement="right" title={toolTipCurrency}>
            <div className="bank-transfer-modal" style={{ cursor: "pointer" }}>
              <div className="d-flex align-items-center">
                <img src={USDWallet?.icon} alt="" className="curImage" />

                <p className="">
                  <span>USD balance</span>
                  <br />
                  USD &nbsp;
                  {formatMoney(USDWallet?.amount)}
                </p>
              </div>
            </div>
          </Tooltip>

          <br />
          <br />

          <button
            className="btn-btn-btn"
            style={{ cursor: "pointer" }}
            disabled={!selectedUser || !title || !cardBrand}
            onClick={next}
          >
            Continue
          </button>
        </div>
      )}
    </div>
  );
}
