import React, { useState, useEffect } from "react";
import GeneralTable from "../../GeneralTable";
import { formatBalance } from "../../../utilities/helperFunctions";
import makeAPICall from "../../../utilities/apiUtils";
import back from "../../../assets/images/back-button.svg";
import { ReactComponent as DebitIcon } from "../../../assets/images/debit-icon.svg";
import { ReactComponent as CreditIcon } from "../../../assets/images/credit-icon.svg";
import { ReactComponent as FundNewIcon } from "../../../assets/images/add-trans.svg";
import { ReactComponent as WithdrawCardIcon } from "../../../assets/images/fund-card-circle.svg";
import { ReactComponent as DownloadCardIcon } from "../../../assets/images/download-card.svg";

import { Spin, Avatar } from "antd";
import moment from "moment";
import randomColor from "randomcolor";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#FFFFFF",
    padding: "40px",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  image: {
    width: "20%",
  },
  rowTwo: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "50px",
  },
  imageSocial: {
    width: "15px",
    height: "15px",
  },
  imageGray: {
    width: "80px",
    height: "20px",
  },
  date: {
    fontSize: 13,
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#444052",
  },
  mainText: {
    marginTop: 30,
  },
  userName: {
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 13,
    color: "#344054",
    marginTop: 10,
  },
  title: {
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 13,
    color: "#040052",
    padding: "10px 0",
    marginBottom: 10,
    borderBottom: "1px solid #5A50FE",
  },
  newSection: {
    flexDirection: "row",
    marginTop: 15,
  },
  leftText: {
    color: "#444052",
    fontSize: 13,
    fontWeight: 400,
    fontStyle: "normal",
    marginRight: "20px",
    marginBottom: "10px",
    width: "25%",
  },
  rightText: {
    color: "#040052",
    fontSize: 13,
    fontWeight: "bold",
    marginBottom: "10px",
  },
});

export default function CardTransactions({ user, cardDetails }) {
  const now = moment().format("YYYY-MM-DD");
  const dateLimits = {
    today: { from: moment().startOf("day").format("YYYY-MM-DD"), to: now },
    week: {
      from: moment().subtract(1, "week").startOf("day").format("YYYY-MM-DD"),
      to: now,
    },
    month: {
      from: moment().subtract(1, "month").startOf("day").format("YYYY-MM-DD"),
      to: now,
    },
    year: {
      from: moment().subtract(1, "year").startOf("day").format("YYYY-MM-DD"),
      to: now,
    },
  };
  const filterOptions = [
    { label: "Transaction Type", clickable: true },
    { label: "Status", clickable: true },
    { label: "Date", clickable: true },
  ];
  // const filterOptions = [];
  const filterSelectOptions = {
    "Transaction Type": ["Select type", "Credit", "Debit"],
    Status: ["Select status", "Successful", "Failed", "Pending"],
    Date: [
      "Select date",
      "Today",
      "This week",
      "This month",
      "Past year",
      "Custom",
    ],
  };
  // const filterSelectOptions = {};
  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState();
  const [searchController, setSearchController] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState({});
  const [show, setShow] = useState(false);

  // const pagination = {
  //   page: 1,
  //   limit: 10,
  //   total: transactions?.length,
  // };

  const transactionMessages = {
    Funding: "Deposited from USD",
    Withdrawal: "Withdrawal to USD",
  };

  const bgColor = randomColor({
    luminosity: "dark",
  });

  const getTransactions = (params = null) => {
    setLoading(true);
    // If there's an ongoing request, abort it
    if (searchController) {
      searchController.abort();
    }

    // Create a new AbortController for the new request
    const controller = new AbortController();
    setSearchController(controller);
    return makeAPICall({
      path: `cards/transactions/${cardDetails?.card?.id}`,
      method: "GET",
      params,
      useCustomController: controller,
    })
      .then((res) => {
        // console.log(res);
        setTransactions(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.message === "The user aborted a request.") {
          return false;
        } else {
          console.log(err);
        }
      })
      .finally(() => {
        setSearchController(null);
      });
  };

  useEffect(() => {
    getTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customStylesRandom = {
    backgroundColor: bgColor,
  };

  const customStyles = {
    backgroundColor: "#ecebff",
    border: "1px solid #cbcae5",
  };

  const columns = [
    {
      title: "",
      dataIndex: "id",
      key: "type",
      width: 80,
      render: (id, row) => {
        return (
          <div
            style={{ position: "relative" }}
            onClick={() => showTransactions(row)}
          >
            <Avatar
              size={40}
              // className="default-avatar"
              style={
                row?.merchant?.name === "Funding" ||
                row?.merchant?.name === "Withdrawal"
                  ? customStyles
                  : customStylesRandom
              }
              icon={
                row?.merchant?.name === "Funding" ? (
                  <FundNewIcon />
                ) : row?.merchant?.name === "Withdrawal" ? (
                  <WithdrawCardIcon />
                ) : (
                  row?.merchant?.name.charAt(0).toLocaleUpperCase()
                )
              }
            />
            <div className="position-bottom-card">
              {row?.type === "debit" ? <DebitIcon /> : <CreditIcon />}
            </div>
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      width: 580,
      render: (id, row) => {
        return (
          <div onClick={() => showTransactions(row)}>
            <p className="v-card-table">
              {row?.merchant?.name === "Funding" ||
              row?.merchant?.name === "Withdrawal"
                ? transactionMessages[row?.merchant?.name]
                : row?.merchant?.name}
            </p>
            <p className="v-card-time">
              <span className="mr-2">
                {moment(row?.date).format("MMM DD YYYY")}
                {moment(row?.date).format("MMM DD YYYY")}
              </span>
              <span> {moment(row?.date).format("hh:mm A")}</span>
            </p>
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "amount",
      key: "amount",
      render: (amount, row) => (
        <div style={{ fontSize: "15px" }} onClick={() => showTransactions(row)}>
          {row?.type === "debit" ? (
            <span style={{ color: "#F25E6C" }}>
              -{row?.currency}&nbsp; {formatBalance(amount)}
            </span>
          ) : (
            <span style={{ color: "#0F730E" }}>
              +{row?.currency}&nbsp; {formatBalance(amount)}
            </span>
          )}
        </div>
      ),
    },
  ];

  function showTransactions(row) {
    setTransactionDetails(row);
    setShow(true);
  }

  const filterTransactions = (value, custom) => {
    if (
      (value === "Today" ||
        value === "This week" ||
        value === "This month" ||
        value === "Past year") &&
      !custom
    ) {
      const params = {
        from: dateLimits[
          value === "Today"
            ? "today"
            : value === "This week"
            ? "week"
            : value === "This month"
            ? "month"
            : value === "Past year"
            ? "year"
            : "today"
        ].from,
        to: dateLimits[
          value === "Today"
            ? "today"
            : value === "This week"
            ? "week"
            : value === "This month"
            ? "month"
            : value === "Past year"
            ? "year"
            : "today"
        ].to,
      };
      getTransactions(params);
    } else if (value === "Custom") {
      const params = {
        from: custom.from,
        to: custom.to,
      };
      getTransactions(params);
    } else if (value === "cleared") {
      getTransactions();
    } else if (
      value === "Successful" ||
      value === "Failed" ||
      value === "Pending"
    ) {
      const params = {
        status: value,
      };
      getTransactions(params);
    } else {
      const params = {
        type: value === "Debit" ? "debit" : "credit",
      };
      getTransactions(params);
    }
  };

  const paginateAll = (page, pageSize) => {
    const params = {
      limit: pageSize,
      page: page,
    };
    getTransactions(params);
  };

  const handleInputChange = (value) => {
    const params = {
      query: value,
    };
    // console.log(params);
    getTransactions(params);
  };

  const prev = () => {
    setShow(false);
  };

  const MyDocument = () => (
    <Document>
      <Page style={styles.page}>
        <View style={styles.row}>
          <Image style={styles.image} src="/eversend_receipt_logo.png" />
          <Text style={styles.date}>
            {moment(transactionDetails?.date).format("MMM DD YYYY")}&nbsp;
            {moment(transactionDetails?.date).format("hh:mm A")}
          </Text>
        </View>
        <View style={styles.mainText}>
          <Text style={styles.userName}>Hi {user?.account?.name},</Text>
          <Text style={styles.userName}>
            You have created a transaction on your virtual card
          </Text>
        </View>
        <View style={styles.mainText}>
          <Text style={styles.title}>Transaction Details</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Card scheme</Text>
          <Text style={styles.rightText}>{cardDetails?.card?.brand}</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Card number</Text>
          <Text style={styles.rightText}>{cardDetails?.card?.mask}</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Transaction type</Text>
          <Text style={styles.rightText}>{transactionDetails?.type}</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Transaction status</Text>
          <Text style={styles.rightText}>{transactionDetails?.status}</Text>
        </View>

        <View style={styles.newSection}>
          <Text style={styles.leftText}>Payment amount</Text>
          <Text style={styles.rightText}>{transactionDetails?.amount}</Text>
        </View>

        <View style={styles.newSection}>
          <Text style={styles.leftText}>Currency</Text>
          <Text style={styles.rightText}>{transactionDetails?.currency}</Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Balance before</Text>
          <Text style={styles.rightText}>
            {transactionDetails?.balanceBefore}
          </Text>
        </View>
        <View style={styles.newSection}>
          <Text style={styles.leftText}>Balance after</Text>
          <Text style={styles.rightText}>
            {transactionDetails?.balanceAfter}
          </Text>
        </View>
        <View style={styles.mainText}>
          <Text style={styles.userName}>Thanks,</Text>
          <Text style={styles.userName}>The Eversend team</Text>
        </View>
        <View style={styles.rowTwo}>
          <Image style={styles.imageGray} src="/eversend_logo_gray.png" />
          <Text style={styles.userName}>&nbsp;</Text>
          {/* <Link src="https://twitter.com">
            <Image style={styles.imageSocial} src="/twitter.png" />
          </Link> */}
          {/* <Link src="https://twitter.com">
            <Image style={styles.imageSocial} src="/facebook.png" />
          </Link>
          <Link src="https://twitter.com">
            <Image style={styles.imageSocial} src="/instagram.png" />
          </Link> */}
        </View>
      </Page>
    </Document>
  );

  return (
    <div>
      {show ? (
        <div className="p-3" style={{ maxWidth: "500px", margin: "auto" }}>
          <div className="card-transaction-details-styling">
            <div
              className="d-flex align-items-center"
              onClick={prev}
              style={{ cursor: "pointer", marginBottom: "10px" }}
            >
              <img src={back} alt="back arrow" />
              <p className="ml-2 money-request-p">Transaction</p>
            </div>
            <div className="text-center">
              <h1>
                {transactionDetails?.merchant?.name === "Funding" ||
                transactionDetails?.merchant?.name === "Withdrawal"
                  ? transactionMessages[transactionDetails?.merchant?.name]
                  : transactionDetails?.merchant?.name}
              </h1>
              <p className="mb-4">
                <span className="mr-2">
                  {moment(transactionDetails?.date).format("MMM DD YYYY")}
                </span>
                <span>
                  {moment(transactionDetails?.date).format("hh:mm A")}
                </span>
              </p>
              <div className="d-flex justify-content-between align-items-center">
                <h2>Card scheme</h2>
                <h3>{cardDetails?.card?.brand}</h3>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h2>Card number</h2>
                <h3>{cardDetails?.card?.mask}</h3>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h2>Transaction type</h2>
                <h3>{transactionDetails?.type}</h3>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h2>Transaction status</h2>
                <h3>{transactionDetails?.status}</h3>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h2>Payment amount</h2>
                <h3>{transactionDetails?.amount}</h3>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h2>Currency</h2>
                <h3>{transactionDetails?.currency}</h3>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h2>Balance before</h2>
                <h3>{transactionDetails?.balanceBefore}</h3>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h2>Balance after</h2>
                <h3>{transactionDetails?.balanceAfter}</h3>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <h2>Receipt</h2>
                <div className="d-flex justify-content-between align-items-center">
                  <DownloadCardIcon className="mr-2" />
                  <PDFDownloadLink
                    document={<MyDocument />}
                    fileName="transaction.pdf"
                  >
                    {({ blob, url, loading, error }) =>
                      loading ? "Loading document..." : "Download"
                    }
                  </PDFDownloadLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Spin spinning={loading}>
          <GeneralTable
            columns={columns}
            placeHolder="Search Transactions"
            filter={filterOptions}
            filterSelect={filterSelectOptions}
            download={false}
            filterTransactions={filterTransactions}
            handleInputChange={handleInputChange}
            pagination={transactions}
            paginateAll={paginateAll}
            data={transactions?.transactions}
          />
        </Spin>
      )}
    </div>
  );
}
