import React, { useState, useEffect } from "react";
import { Switch, Router } from "react-router-dom";
import { IdleTimerProvider, useIdleTimer } from "react-idle-timer";
import history from "../../utilities/history";
import { Modal, message, Button } from "antd";

import { SESSION_HASH } from "../../utilities/constants";
import makeAPICall from "../../utilities/apiUtils";
import "../../assets/css/style.css";
import PublicRouteCheck from "../../routes/Public.routes";
import PrivateRouteCheck from "../../routes/Private.routes";
import LoginContainer from "../../pages/auth/login/Login.container";
import TwoFactorEmail from "../../pages/auth/login/TwoFactorEmail";
import ResetContainer from "../../pages/auth/reset/ResetContainer";
import SignupContainer from "../../pages/auth/signup/SignupContainer";
import VerificationEmail from "../../pages/auth/signup/VerificationEmail";
import VerificationSuccess from "../../pages/auth/signup/VerificationSuccess";
import Routes from "../../routes/Dashboard.routes";
import FinishBeneficiaryBulkUpload from "../modals/beneficiaries/FinishBeneficiaryBulkUpload";
import ExchangeModal from "../modals/exchange";
import AddMoneyModal from "../modals/addMoneyWallet";
import PayoutModal from "../modals/payout";
import CreateCardModal from "../modals/createCard";
import BeneficiaryModal from "../modals/payout/beneficiary";
import { useSelector, useDispatch } from "../../utilities/hooks";
import {
  toggleExchangeModalAction,
  toggleAddMoneyModalAction,
  toggleSendMoneyModalAction,
  toggleSendMoneyBeneficiaryModalAction,
  toggleCreateCardModalAction,
} from "../../services/actions";
import { isLogin } from "../../utilities/authUtils";
// import { key } from "../../utilities/helperFunctions";
// import displayErrorMessage from "../notifyBox";

function App(props) {
  message.config({
    duration: 5,
    maxCount: 1,
    rtl: false,
  });

  const { showModal } = useSelector(
    (state) => state.beneficiariesReducer.bulkUpload
  );
  const data = window.localStorage.getItem(SESSION_HASH);
  const dispatch = useDispatch();
  const { reset } = useIdleTimer();
  const {
    isExchange,
    isExchangeValue,
    isAddMoney,
    isAddMoneyValue,
    isSendMoney,
    isCreateCard,
    isSendMoneyBeneficiary,
    isSendMoneyBeneficiaryValue,
    isSendMoneyState,
    isType,
  } = useSelector((state) => state.walletsReducer);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [seconds, setSeconds] = useState();

  // console.log(seconds, "test");

  const logOut = () => {
    history.push("/login");
    return makeAPICall({
      path: `auth/logout?session=${data}`,
      method: "GET",
    })
      .then((res) => {
        if (res) {
          window.localStorage.clear();
          window.location.reload();
        }
      })
      .catch((err) => {
        console.log(err);
        window.localStorage.clear();
        window.location.reload();
      });
  };

  const handleOnIdle = () => {
    // HIDE YOUR MODAL HERE AND LoGOUT
    setIsModalOpen(false);
    logOut();
  };

  const onClose = () => {
    dispatch(toggleExchangeModalAction());
  };

  const handleCloseModal = () => {
    dispatch(toggleAddMoneyModalAction());
  };

  const handleCloseCreateCardModal = () => {
    dispatch(toggleCreateCardModalAction());
  };

  const handleClosePayoutModal = () => {
    dispatch(toggleSendMoneyModalAction());
  };

  const handleCloseBeneficiaryPayoutModal = () => {
    dispatch(toggleSendMoneyBeneficiaryModalAction());
  };

  const onPrompt = () => {
    // Fire a Modal Prompt
    setIsModalOpen(true);
    setSeconds(60);
  };

  const handleOk = () => {
    message.success("You will be logged out due to inactivity");
    setIsModalOpen(false);
    logOut();
  };

  const onActive = (event) => {
    // Close Modal Prompt
    handleCancel();
  };

  function handleCancel() {
    reset();
    setSeconds();
    setIsModalOpen(false);
  }

  useEffect(() => {
    if (!seconds) return;

    const intervalId = setInterval(() => {
      setSeconds(seconds - 1);
    }, 1000);

    if (seconds <= 0) {
      logOut();
    }

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  return (
    <React.Fragment>
      <Router history={history}>
        <Switch>
          <PublicRouteCheck
            exact
            path="/login"
            component={LoginContainer}
            title={"Login"}
          />
          <PublicRouteCheck
            exact
            path="/auth"
            component={TwoFactorEmail}
            title={"2FA Auth"}
          />
          <PublicRouteCheck
            exact
            path="/forgot-password"
            component={ResetContainer}
            title={"Forgot Password"}
          />
          <PublicRouteCheck
            exact
            path="/signup"
            component={SignupContainer}
            title={"Register"}
          />
          <PublicRouteCheck
            exact
            path="/verify"
            component={VerificationEmail}
            title={"Verify Email"}
          />
          <PublicRouteCheck
            exact
            path="/success"
            component={VerificationSuccess}
            title={"Success"}
          />
          <IdleTimerProvider
            timeout={1000 * 60 * 15} // 15 minutes
            promptTimeout={1000 * 60 * 14} // 14 minutes
            onPrompt={onPrompt}
            onActive={onActive}
            onIdle={handleOnIdle}
          >
            <PrivateRouteCheck path="/" component={Routes} {...props} />
          </IdleTimerProvider>
        </Switch>
      </Router>
      {isLogin() ? (
        <React.Fragment>
          <div className="auth-modal-auto">
            {isModalOpen ? (
              <Modal
                title="You Have Been Idle!"
                visible={isModalOpen}
                centered
                closable={false}
                footer={[
                  <Button
                    type="primary"
                    onClick={handleOk}
                    style={{
                      background: "#F0F0F0",
                      borderRadius: "4px",
                      border: "none",
                      color: "#5A50FE",
                      width: "30%",
                    }}
                  >
                    Logout
                  </Button>,
                  <Button
                    type="primary"
                    onClick={handleCancel}
                    style={{
                      background: "#5A50FE",
                      borderRadius: "4px",
                      width: "30%",
                    }}
                  >
                    Stay
                  </Button>,
                ]}
              >
                <p>You will be signed out in {seconds}. Do you want to stay?</p>
              </Modal>
            ) : null}
          </div>

          {showModal ? <FinishBeneficiaryBulkUpload show={showModal} /> : null}
          {isExchange ? (
            <ExchangeModal
              exchangeCurrency={isExchangeValue}
              show={isExchange}
              onClose={onClose}
            />
          ) : null}
          {isAddMoney ? (
            <AddMoneyModal
              show={isAddMoney}
              currentWalletDefault={isAddMoneyValue}
              onClose={handleCloseModal}
              type={isType}
            />
          ) : null}
          {isSendMoney ? (
            <PayoutModal onClose={handleClosePayoutModal} show={isSendMoney} />
          ) : null}
          {isCreateCard ? (
            <CreateCardModal
              onClose={handleCloseCreateCardModal}
              show={isCreateCard}
            />
          ) : null}
          {isSendMoneyBeneficiary ? (
            <BeneficiaryModal
              onClose={handleCloseBeneficiaryPayoutModal}
              show={isSendMoneyBeneficiary}
              beneficiary={isSendMoneyBeneficiaryValue}
              isFirstTime={isSendMoneyState}
            />
          ) : null}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {onClose} {handleCloseModal} {handleClosePayoutModal}
          {handleCloseCreateCardModal}
          {handleCloseBeneficiaryPayoutModal}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default App;
