import React from "react";
import { useSelector } from "../../../utilities/hooks.js";
import SharedAuthLayout from "../../../components/layouts/Auth.layout.jsx";
import ForgotPasswordView from "./ForgotPasswordView.jsx";
import VerifyCodeView from "./VerifyCodeView.jsx";
import CreateNewPasswordView from "./CreateNewPasswordView.jsx";

export default function ResetContainer() {
  const { email, code } = useSelector((state) => state.forgotPasswordReducer);

  return (
    <div className="bg-light-grey auth-container">
      <div>
        <SharedAuthLayout
          title={
            !email && !code
              ? "Forgot password?"
              : email && !code
              ? "Enter Verification Code"
              : null
          }
          paragraph={
            !email && !code
              ? "Enter the email you signed up with and we will send you instructions on how to reset your password."
              : email && !code
              ? "We have sent you a verification code to your email address. Please enter the 6 digit code sent to your email"
              : null
          }
        >
          <div>
            {!email && !code ? <ForgotPasswordView /> : null}
            {email && !code ? <VerifyCodeView email={email} /> : null}
            {email && code ? (
              <CreateNewPasswordView email={email} code={code} />
            ) : null}
          </div>
        </SharedAuthLayout>
      </div>
    </div>
  );
}
