import React, { useState, useEffect } from "react";
import accessIcon from "../../assets/images/noAccess.svg";
import Loader from "../../components/loaders/loading";
import { useSelector, useDispatch } from "../../utilities/hooks";
import { useIntercom } from "react-use-intercom";
import { fetchLogsAction } from "../../services/actions";
import { formatDate } from "../../utilities/helperFunctions";
import { Spin } from "antd";
import GeneralTable from "../../components/GeneralTable";
import moment from "moment";

export default function ActivityLogs() {
  const { show, boot } = useIntercom();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.profileReducer);
  const { loading, activities } = useSelector(
    (state) => state.activityLogReducer
  );
  const [hasKyc, setHasKyc] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const filterOptions = [{ label: "Date", clickable: true }];
  // const filterOptions = [];
  const filterSelectOptions = {
    Date: [
      "Select date",
      "Today",
      "This week",
      "This month",
      "Past year",
      "Custom",
    ],
  };

  const now = moment().format("YYYY-MM-DD");
  const dateLimits = {
    today: { from: moment().startOf("day").format("YYYY-MM-DD"), to: now },
    week: {
      from: moment().subtract(1, "week").startOf("day").format("YYYY-MM-DD"),
      to: now,
    },
    month: {
      from: moment().subtract(1, "month").startOf("day").format("YYYY-MM-DD"),
      to: now,
    },
    year: {
      from: moment().subtract(1, "year").startOf("day").format("YYYY-MM-DD"),
      to: now,
    },
  };

  // console.log(activities);

  boot({
    name: user?.account?.name,
    email: user?.account?.email,
    user_id: user?.account?.id,
    isB2B: "B2B",
  });

  useEffect(() => {
    if (user.account && user.account.isVerified === false) {
      setHasKyc(false);
    } else {
      dispatch(fetchLogsAction({}));
      setHasKyc(true);
    }
  }, [user, dispatch]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  const columns = [
    {
      title: "Date time",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt) => {
        return formatDate(createdAt);
      },
    },
    {
      title: "Name",
      dataIndex: "id",
      key: "id",
      render: (id, user) => {
        return `${user?.user?.firstName} ${user?.user?.lastName}`;
      },
    },
    {
      title: "Email",
      dataIndex: "id",
      key: "id",
      render: (id, user) => {
        return user?.user?.email;
      },
    },
    {
      title: "Role",
      dataIndex: "id",
      key: "id",
      render: (id, user) => {
        return user?.user?.role;
      },
    },
    {
      title: "Activity",
      dataIndex: "comment",
      key: "comment",
    },
  ];

  const paginateAll = (page, pageSize) => {
    const params = {
      limit: pageSize,
      page: page,
    };
    dispatch(fetchLogsAction({ params }));
  };

  const filterTransactions = (value, custom) => {
    if (
      (value === "Today" ||
        value === "This week" ||
        value === "This month" ||
        value === "Past year") &&
      !custom
    ) {
      dispatch(
        fetchLogsAction({
          params: {
            from: dateLimits[
              value === "Today"
                ? "today"
                : value === "This week"
                ? "week"
                : value === "This month"
                ? "month"
                : value === "Past year"
                ? "year"
                : "today"
            ].from,
            to: dateLimits[
              value === "Today"
                ? "today"
                : value === "This week"
                ? "week"
                : value === "This month"
                ? "month"
                : value === "Past year"
                ? "year"
                : "today"
            ].to,
          },
        })
      );
    } else {
      dispatch(
        fetchLogsAction({
          params: {
            from: custom.from,
            to: custom.to,
          },
        })
      );
      // console.log(value, custom, 'text');
    }
  };

  const handleInputChange = (value) => {
    dispatch(
      fetchLogsAction({
        params: {
          query: value,
        },
      })
    );
  };

  return (
    <div>
      <div>
        <h1 className="text-md color-bold text-bold mt-2">Activity logs</h1>
      </div>
      {hasKyc ? (
        <div>
          {isLoading ? (
            <Loader />
          ) : (
            <div className="card-table-h1 mt-4">
              {/* <h1>Card information</h1> */}
              <Spin spinning={loading}>
                <GeneralTable
                  columns={columns}
                  placeHolder="Search email, name, role"
                  filter={filterOptions}
                  filterSelect={filterSelectOptions}
                  download={false}
                  filterTransactions={filterTransactions}
                  handleInputChange={handleInputChange}
                  pagination={activities}
                  paginateAll={paginateAll}
                  data={activities?.logs}
                />
              </Spin>
            </div>
          )}
        </div>
      ) : (
        <div className="completed-kyc-state">
          <div className="row">
            <div className="col-md-12 text-center">
              <img src={accessIcon} alt="" />
              <h1>You do not have access</h1>
              <p>
                Please complete your KYB verification, to be able to view
                contents of this page, click on the “complete KYB” button
              </p>
              <button onClick={show} style={{ cursor: "pointer" }}>
                Complete KYB
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
