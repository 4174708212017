import React from "react";
import { Button, Input, Row, Col, Typography, Form } from "antd";

const { TextArea } = Input;
const { Title } = Typography;

export default function AdditionalInformation({ onClick, reason, onChange }) {
  return (
    <div style={{ padding: "20px" }}>
      <Title level={4} className="color-bold" style={{ fontSize: "16px" }}>
        Additional Information
      </Title>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label="Note"
            labelCol={{ span: 24 }}
            style={{ fontSize: "16px" }}
          >
            <TextArea
              className="text-area-additional-info"
              placeholder="Text..."
              rows={8}
              style={{ marginBottom: "20px" }}
              value={reason}
              onChange={(e) => onChange(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="end" align="middle" gutter={16}>
        <Col>
          <Button
            type="text"
            onClick={onClick}
            style={{
              backgroundColor: "#F0F0F0",
              color: "#5A50FE",
              fontWeight: "bold",
              border: "none",
            }}
          >
            Skip
          </Button>
        </Col>
        <Col>
          <Button
            size="large"
            type="primary"
            onClick={onClick}
            style={{
              backgroundColor: "#5A50FE",
              color: "#FFFFFF",
              fontWeight: "bold",
              opacity: !reason ? 0.6 : 1,
              cursor: !reason ? "not-allowed" : "pointer",
            }}
            disabled={!reason}
          >
            Continue
          </Button>
        </Col>
      </Row>
    </div>
  );
}
