import React, { useState } from "react";
// import { Button } from "antd";
import { formatBalance } from "../../utilities/helperFunctions";
import { Spin } from "antd";
import add from "../../assets/images/add-icon-new.svg";
import { activateWalletAction } from "../../services/actions";
import { useSelector, useDispatch } from "../../utilities/hooks";
import { AccessControl } from "accesscontrol";

export default function AddWallet({ goBack, enabled, wallets, user }) {
  // const [walletTest, setWalletTest] = useState(wallets);
  const { activeWalletLoading } = useSelector(
    (state) => state.activateDeactiveReducer
  );
  const { roles } = useSelector((state) => state.RoleReducer);

  const [isLoading, setIsLoading] = useState({
    loading: false,
    key: 0,
  });

  // const { transactions, loading: loadingTransactions } = useSelector(
  //   (state) => state.transactionsReducer
  // );
  const dispatch = useDispatch();

  const ac = new AccessControl(roles?.permissions);

  function checkAccess(value) {
    const permission = ac.can(user?.role).createAny(value);
    return permission;
  }

  const disabledWallets = wallets.filter((wallet) => {
    return wallet.enabled === false;
  });

  const enabledWallets = wallets.filter((wallet) => {
    return wallet.enabled === true;
  });

  const activateWallet = (item, position) => {
    setIsLoading({ loading: true, key: position });
    dispatch(activateWalletAction({ currency: item }));
  };

  return (
    <>
      <div>
        <h1 className="singleWallet-h1">
          <span onClick={goBack}>Wallets</span>&nbsp;
          <i className="fa fa-angle-right"></i>&nbsp; Add wallets
        </h1>
      </div>
      <section>
        <div className="row">
          <div className="col-md-8">
            <br />
            <br />
            <div className="row">
              <div className="col-md-8 align-self-center">
                <p className="left-par">
                  {enabledWallets.length} active wallets
                </p>
              </div>
              <div className="col-md-4 text-right">
                {/* <Button size="large" type="primary" onClick={() => {}}>
                  Save Changes
                </Button> */}
              </div>
            </div>
            <br />
            <section className="current-wallet-active">
              <div className="row">
                {enabled
                  .sort((a, b) => a.position - b.position)
                  .map((wallet, index) => (
                    <div className="col-md-6" key={index}>
                      <div className="wallet-card">
                        <div
                          className="pad-Wallet"
                          style={{ marginBottom: "20px" }}
                        >
                          <div className="d-flex">
                            <img
                              src={wallet.icon}
                              alt=""
                              className="align-self-center curImage"
                            />

                            <p className="">
                              <span>{wallet.name}</span>
                              <br />
                              <br />
                              {wallet.currency} {formatBalance(wallet.amount)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </section>
          </div>
          <div className="col-md-4">
            <h1 className="singleWallet-h1">
              Inactive wallets ({disabledWallets.length})
            </h1>
            <p className="wallet-single-h1">
              Click on activate to make a wallet active
            </p>
            <br />
            {wallets.map((wallet, index) =>
              wallet.enabled === false ? (
                <div className="d-flex wallet-activate" key={index}>
                  <img
                    src={wallet.icon}
                    alt=""
                    className="align-self-center img-flag"
                  />
                  <p className="currency-type">
                    <span>{wallet.name}</span>
                    <br />
                    {wallet.currency} {formatBalance(wallet.amount)}
                  </p>
                  {checkAccess("wallets").granted ? (
                    <p
                      className="ml-auto align-self-center"
                      style={{ cursor: "pointer" }}
                      onClick={() => activateWallet(wallet.currency, index)}
                    >
                      {activeWalletLoading &&
                      isLoading.loading &&
                      isLoading.key === index ? (
                        <Spin size="small" tip="Loading..." />
                      ) : (
                        <>
                          <img src={add} alt="activate" />
                          &nbsp; Activate
                        </>
                      )}
                    </p>
                  ) : null}
                </div>
              ) : null
            )}
          </div>
        </div>
      </section>
    </>
  );
}
