import React, { useEffect, useState } from "react";
import { Table, Avatar } from "antd";
import { useLocation } from "react-router-dom";
import randomColor from "randomcolor";
import { useIntercom } from "react-use-intercom";

import { useSelector, useDispatch } from "../../utilities/hooks";
import {
  fetchTransactionsAction,
  fetchCountryAction,
  toggleSendMoneyModalAction,
  toggleSendMoneyBeneficiaryModalAction,
} from "../../services/actions";
import EmptyState from "../../components/emptyState";
import EmptyStateIcon from "../../assets/images/emptystatepayout.svg";
import PayoutButton from "../../components/Buttons/PayoutButton";
import UserIcon from "../../assets/images/user.svg";
import GroupIcon from "../../assets/images/users.svg";
// import SomeoneElseContainer from "./payoutSomeoneElse";
import { formatBalance } from "../../utilities/helperFunctions";
import Loader from "../../components/loaders/loading";
import accessIcon from "../../assets/images/noAccess.svg";
import TagManager from "react-gtm-module";
import { AccessControl } from "accesscontrol";

// function handleMenuClick(e) {}

// const menu = (
//   <Menu onClick={handleMenuClick}>
//     <Menu.Item key="1">Monthly Report</Menu.Item>
//     <Menu.Item key="2">Yearly Report</Menu.Item>
//   </Menu>
// );

const tagManagerArgsSend = {
  dataLayer: {
    event: "b2b_send_money_btn",
  },
};

export default function PayoutsContainer() {
  //     const { roles } = useSelector((state) => state.RoleReducer);
  // console.log(roles?.permissions, "tetets");

  const location = useLocation();
  const { show, boot } = useIntercom();
  const { transactions, loading } = useSelector(
    (state) => state.transactionsReducer
  );
  const { user } = useSelector((state) => state.profileReducer);
  const { roles } = useSelector((state) => state.RoleReducer);
  boot({
    name: user?.account?.name,
    email: user?.account?.email,
    user_id: user?.account?.id,
    isB2B: "B2B",
  });
  const dispatch = useDispatch();
  const [hasKyc, setHasKyc] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [isFirstTime, setIsFirstTime] = React.useState(true);

  // console.log(loading, transactions, 'tetetetet')

  const ac = new AccessControl(roles?.permissions);

  function checkAccess(value) {
    const permission = ac.can(user?.role).createAny(value);
    return permission;
  }

  useEffect(() => {
    dispatch(
      fetchTransactionsAction({
        params: {
          type: "payout",
          limit: 10,
        },
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (location?.state?.id && isFirstTime) {
      TagManager.dataLayer(tagManagerArgsSend);
      dispatch(toggleSendMoneyBeneficiaryModalAction(location?.state));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, isFirstTime]);

  useEffect(() => {
    // dispatch(fetchProfileAction());
    // dispatch(fetchBanksAction({}));
    dispatch(fetchCountryAction({}));
  }, [dispatch]);

  useEffect(() => {
    if (user.account && user.account.isVerified === false) {
      setHasKyc(false);
    } else {
      setHasKyc(true);
    }
  }, [user]);

  function handleOpenPayoutModal() {
    TagManager.dataLayer(tagManagerArgsSend);
    dispatch(toggleSendMoneyModalAction());
  }

  function handleOpenPayoutModalBeneficiary() {
    TagManager.dataLayer(tagManagerArgsSend);
    dispatch(toggleSendMoneyBeneficiaryModalAction());
  }

  //table header details
  const columns = [
    {
      dataIndex: ["beneficiary"],
      width: "10%",
      render: (beneficiary) => {
        return (
          <Avatar
            size="large"
            style={{ backgroundColor: randomColor(), verticalAlign: "middle" }}
          >
            {beneficiary?.firstName.charAt(0)}
            {beneficiary?.lastName.charAt(0)}
          </Avatar>
        );
      },
    },
    {
      dataIndex: ["beneficiary"],
      width: "30%",
      render: (beneficiary, row) => {
        return (
          <>
            <p style={{ color: "#1C223C" }}>
              {beneficiary?.firstName} {beneficiary?.lastName}
            </p>
            <span style={{ color: "#48484D" }}>{row?.meta?.type}</span>
          </>
        );
      },
    },
    {
      dataIndex: "amount",
      align: "right",
      render(amount) {
        return `- UGX ${formatBalance(Math.abs(amount))}`;
      },
    },
  ];

  // rowSelection object indicates the need for row selection
  // const rowSelection = {
  //   onChange: (selectedRowKeys, selectedRows) => {
  //     setSelectedRow(selectedRows);
  //   },
  // };

  return (
    <div>
      <h1 className="text-md color-bold text-bold mt-2">Payouts</h1>

      {hasKyc ? (
        <div>
          <div>
            {loading ? (
              <Loader />
            ) : (
              <>
                {!transactions || transactions.transactions?.length === 0 ? (
                  <div className="bg-white">
                    <EmptyState
                      img={EmptyStateIcon}
                      title="Initiate a new payout"
                      description="You have not perfomed any transactions or 
                  added a beneficiary "
                      btnLabel="Single payout"
                      onClick={handleOpenPayoutModal}
                      btnProps={{ maxWidth: "236px" }}
                      // btnLabelTwo="Bulk payout"
                    />
                  </div>
                ) : (
                  <div className="flex">
                    <div className="bg-white col-66">
                      <div className="row">
                        <div
                          className="col-md-12"
                          style={{ position: "relative" }}
                        >
                          <Table
                            // rowSelection={rowSelection}
                            showHeader={false}
                            title={() => "Recent Payouts"}
                            columns={columns}
                            dataSource={transactions.transactions}
                            pagination={false}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-33 pl-2">
                      {checkAccess("payouts").granted ? (
                        <div className="bg-light-grey p-4">
                          <h3 className="text-md color-bold text-bold">
                            Select new payout type
                          </h3>
                          <PayoutButton
                            title="Beneficiaries"
                            icon={GroupIcon}
                            handleClick={handleOpenPayoutModalBeneficiary}
                          />
                          <PayoutButton
                            title="Someone else"
                            icon={UserIcon}
                            handleClick={handleOpenPayoutModal}
                          />
                          {/* <PayoutButton title="Business" icon={UserIcon} /> */}
                        </div>
                      ) : null}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      ) : (
        <div className="completed-kyc-state">
          <div className="row">
            <div className="col-md-12 text-center">
              <img src={accessIcon} alt="" />
              <h1>You do not have access</h1>
              <p>
                Please complete your KYB verification, to be able to view
                contents of this page, click on the “complete KYB” button
              </p>
              <button onClick={show} style={{ cursor: "pointer" }}>
                Complete KYB
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
