import React, { useState, useEffect } from "react";
import PinInput from "react-pin-input";
import { ReactComponent as Success } from "../../../assets/images/confirm_success.svg";
import makeAPICall from "../../../utilities/apiUtils";
import { message } from "antd";
import Loader from "../../../components/Loader";

export default function EmailVerification({
  formDetails,
  verified,
  email,
  changeEmail,
}) {
  const [showTextBox, setShowTextBox] = useState(false);
  const [emailValue, setEmailValue] = useState(email);
  const [otpCodeView, setOtpCodeView] = useState(false);
  const [timeLeft, setTimeLeft] = useState();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pinId, setPinId] = useState("");

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const minutes = Math.floor(timeLeft / 60)
    .toString()
    .padStart(2, "0");
  const secondsFormatted = (timeLeft % 60).toString().padStart(2, "0");

  const updateEmail = (email) => {
    setEmailValue(email);
    changeEmail(email);
  };

  function handleInputPinChange(otpInput) {
    //makeApiCall(otpInput)
    setIsLoading(true);
    const data = {
      email: emailValue,
      pinId: pinId,
      code: otpInput,
    };
    return makeAPICall({
      path: "auth/verifyEmail",
      payload: data,
      method: "POST",
    })
      .then((res) => {
        setIsLoading(false);
        setSuccess(true);
        setOtpCodeView(false);
        setShowTextBox(false);
        setTimeLeft();
        verified();
      })
      .catch((err) => {
        setIsLoading(false);
        message.error(err.message, 5);
      });
  }

  function resendOtp() {
    // makeApiCall()
    setLoading(true);
    const data = {
      firstName: formDetails.firstName,
      email: emailValue,
    };
    return makeAPICall({
      path: "auth/sendEmailVerification",
      payload: data,
      method: "POST",
    })
      .then((res) => {
        setPinId(res.data.result.pinId);
        setTimeLeft(150);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message, 5);
      });
  }

  function sendEmailCode() {
    // makeApiCall()
    setLoading(true);
    const data = {
      firstName: formDetails.firstName,
      email: emailValue,
    };
    return makeAPICall({
      path: "auth/sendEmailVerification",
      payload: data,
      method: "POST",
    })
      .then((res) => {
        setPinId(res.data.result.pinId);
        setLoading(false);
        setTimeLeft(150);
        setOtpCodeView(true);
      })
      .catch((err) => {
        setLoading(false);
        message.error(err.message, 5);
      });
  }

  return (
    <div className="verify-box">
      {success ? (
        <div className="d-flex">
          <Success />
          <p className="verify-otp-view-p" style={{ marginLeft: "10px" }}>
            Success! Your email has been verified.
          </p>
        </div>
      ) : (
        <div>
          {otpCodeView ? (
            <div className="verify-otp-view">
              <p className="verify-otp-view-p">
                We sent a verification code to (<span>{email}</span>).
              </p>
              <p className="verify-otp-view-p">
                Please check your email to proceed.
              </p>
              {isLoading ? (
                <button
                  style={{
                    background: "transparent",
                    outline: "none",
                    border: "none",
                    marginTop: "15px",
                  }}
                >
                  <span className="d-flex align-items-center justify-between">
                    <Loader dark={true} /> Verifying Email
                  </span>
                </button>
              ) : (
                <PinInput
                  focus
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                  inputStyle={{
                    border: "1px solid #CBCAE5",
                    borderRadius: "8px",
                    color: "#949494",
                  }}
                  length={6}
                  type="numeric"
                  onComplete={handleInputPinChange}
                />
              )}

              <hr />
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  <button
                    className="verify-resend-otp-btn"
                    style={{ marginRight: "10px" }}
                    disabled={timeLeft !== 0}
                    onClick={resendOtp}
                  >
                    {loading ? (
                      <span className="d-flex align-items-center justify-between">
                        <Loader dark={true} /> Resending
                      </span>
                    ) : (
                      <span>Resend OTP {timeLeft ? "in" : null}</span>
                    )}
                  </button>
                  {timeLeft ? (
                    <h1 className="timer-class">
                      {minutes}:{secondsFormatted}
                    </h1>
                  ) : null}
                </div>
                {timeLeft ? null : (
                  <button
                    className="verify-resend-otp-btn"
                    onClick={() => {
                      setOtpCodeView(false);
                      setTimeLeft(0);
                      setShowTextBox(true);
                    }}
                  >
                    Re-enter Email
                  </button>
                )}
              </div>
            </div>
          ) : (
            <div className="verify-edit">
              <h1 className="verify-title">Email Verification</h1>
              {showTextBox ? (
                <div className="d-flex justify-content-between">
                  <input
                    type="email"
                    value={emailValue.trim()}
                    placeholder="Text"
                    className="re-enter-email-input"
                    onChange={(e) => updateEmail(e.target.value)}
                  />
                  <button
                    className="verify-confirm-btn"
                    onClick={() => {
                      setShowTextBox(false);
                    }}
                    disabled={!emailValue}
                  >
                    Confirm
                  </button>
                </div>
              ) : (
                <div>
                  <div className="d-flex justify-content-between email-edit-box">
                    <p className="verify-email-text">{email}</p>
                    <button
                      className="verify-edit-btn"
                      onClick={() => {
                        setShowTextBox(true);
                      }}
                    >
                      Edit
                    </button>
                  </div>
                  <button
                    className="verify-btn"
                    onClick={sendEmailCode}
                    disabled={loading}
                    type="submit"
                  >
                    {loading ? (
                      <span className="d-flex align-items-center justify-between">
                        <Loader dark={false} /> Verify email
                      </span>
                    ) : (
                      "Verify email"
                    )}
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
