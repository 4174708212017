import React, { useState } from "react";
import { useSelector } from "../../../utilities/hooks";
import { InputNumber, message } from "antd";
import Loader from "../../Loader";

import makeAPICall from "../../../utilities/apiUtils";
import { formatMoney } from "../../../utilities/helperFunctions";

export default function Third({ next, card, cardDetails }) {
  const { wallets } = useSelector((state) => state.walletsReducer);
  const [amount, setAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [lowBalance, setLowBalance] = useState(false);

  const minimumFunding = 1;
  const creationFee = 1;
  const creationFeeNonSubscription = 3;

  let USDWallet = wallets.find((wallet) => wallet.currency === "USD");

  const onChange = (value) => {
    setAmount(value);

    if (!value) {
      setCanSubmit(false);
      return;
    }

    if (value < minimumFunding) {
      setCanSubmit(false);
      return;
    }

    if (value > USDWallet.amount) {
      setLowBalance(true);
      setCanSubmit(false);
      return;
    }

    if (
      value -
        (card.isNonSubscription ? creationFeeNonSubscription : creationFee) <=
      0
    ) {
      setCanSubmit(false);
      return;
    }

    setCanSubmit(true);
    setLowBalance(false);
  };

  const handleSubmit = () => {
    const totalCost =
      amount -
      (card.isNonSubscription ? creationFeeNonSubscription : creationFee);

    if (USDWallet.amount >= totalCost) {
      const data = {
        title: card.title,
        isNonSubscription: card.isNonSubscription,
        userId: card.userId.toString(),
        color: card.colors ? card.colors : "blue",
        amount: totalCost,
        brand: card.brand, //visa or mastercard
        currency: "USD",
      };

      setLoading(true);

      return makeAPICall({
        path: "cards",
        method: "POST",
        payload: data,
      })
        .then((res) => {
          // console.log(res);
          cardDetails({ newCard: res.data });
          setLoading(false);
          next();
        })
        .catch((err) => {
          setLoading(false);
          message.error(err.message, 5);
        });
    } else if (USDWallet.amount >= minimumFunding) {
      message.error("Insufficient funds", 5);
    } else {
      message.error("Insufficient funds", 5);
    }
  };

  return (
    <>
      {USDWallet && (
        <div>
          <div className="banktransfer-others">
            <div style={{ position: "relative" }}>
              <div className="custom-select-card">
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    className="d-flex align-items-center p-2"
                    style={{ backgroundColor: "#F8F9FA", borderRadius: "40px" }}
                  >
                    <img
                      src={USDWallet?.icon}
                      alt=""
                      className="rounded-circle"
                      width="24px"
                      height="24px"
                    />
                    <p className="ml-2">USD</p>
                  </div>

                  <p>Fund card</p>
                </div>

                <div className="d-flex align-items-center justify-content-between mt-2">
                  <p style={{ color: lowBalance ? "#c00" : "#444052" }}>
                    Balance: USD {formatMoney(USDWallet.amount)}
                  </p>
                  <div style={{ textAlign: "right" }}>
                    <InputNumber
                      value={amount}
                      formatter={(value) =>
                        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }
                      parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                      onChange={onChange}
                      bordered={false}
                    />
                  </div>
                </div>
              </div>
              <div className="fees-card-area mt-4">
                <div className="d-flex align-items-center justify-content-between mt-2">
                  <p>Debit from your wallet</p>
                  <p>USD {amount ? amount : 0}</p>
                </div>

                {card.isNonSubscription ? (
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <p>Card creation fee</p>
                    <p>USD {creationFeeNonSubscription}</p>
                  </div>
                ) : (
                  <div className="d-flex align-items-center justify-content-between mt-2">
                    <p>Monthly maintenance fee</p>
                    <p>USD {creationFee}</p>
                  </div>
                )}

                <hr />

                <div className="d-flex align-items-center justify-content-between mt-2">
                  <b>Credit to virtual card</b>
                  <b>
                    USD{" "}
                    {amount <
                    (card.isNonSubscription
                      ? creationFeeNonSubscription
                      : creationFee)
                      ? 0
                      : amount -
                        (card.isNonSubscription
                          ? creationFeeNonSubscription
                          : creationFee)}
                  </b>
                </div>
              </div>
            </div>
            <button
              className="btn-btn-btn mt-4"
              style={{ cursor: "pointer" }}
              disabled={!amount || loading || !canSubmit}
              onClick={handleSubmit}
            >
              {loading ? (
                <span className="d-flex align-items-center justify-between">
                  <Loader dark={false} />
                </span>
              ) : (
                "Create card"
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
